<template>
 
    <div class="wrapper mx-auto flex flex-col justify-center items-center">
            <img class="w-6/12 mt-3" :src="$store.state.vendor.logo" alt="">
            <p class="mt-4 mb-1 text-3xl">{{$t('word98')}}</p>
            <p v-show="reset_status == 2" class="text-gray-600 text-sm"> {{$t('word56')}} <b>+{{ $store.state.vendor.phone_code }} {{ reset_phone }}</b></p>
            <div class="mb-4"></div>
            <div v-if="reset_error == 1" class="bg-red-600 text-white w-full text-sm rounded font-medium p-3 text-center mb-3">
                <span>{{$t('word63')}}</span>
            </div>
            <div v-else-if="reset_error == 2" class="bg-red-600 text-white w-full text-sm rounded font-medium p-3 text-center mb-3">
                <span>{{$t('word53')}}</span>
            </div>
            <div v-else-if="reset_error == 3" class="bg-red-600 text-white w-full text-sm rounded font-medium p-3 text-center mb-3">
                <span>{{$t('word145')}}</span>
            </div>
            <div v-else-if="reset_error == 5" class="bg-red-600 text-white w-full text-sm rounded font-medium p-3 text-center mb-3">
                <span>{{$t('word58')}}</span>
            </div>
            <div v-else-if="reset_error == 6" class="bg-red-600 text-white w-full text-sm rounded font-medium p-3 text-center mb-3">
                <span>{{$t('word100')}}</span>
            </div>
            <div v-else-if="reset_error == 999" class="bg-green-600 text-white w-full text-sm rounded font-medium p-3 text-center mb-3">
                <span>{{$t('word59')}}</span>
            </div>
            
            <div v-show="reset_status == 1" class="w-full relative">
                <input type="text" v-model="reset_phone"
                class="form-control w-full py-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                :placeholder="$t('word52')" style="padding-left: 70px"/>
                <span class="absolute text-xl font-normal text-gray-500" style="top: 24.6%; left: 12px">+{{ $store.state.vendor.phone_code }}</span>
            </div>

            <p v-show="reset_status == 1" style="font-size: 10px" class="text-center text-gray-700 mt-4">
                {{$t('word152')}} <br>
                <a class="text-blue-600" target="_blank" href="https://my.yola.menu/media/agreement.pdf"> {{$t('word110')}}</a>
            </p>

            <div v-show="reset_status == 2" class="w-full relative">
                <input type="tel" v-model="reset_code"
                class=" form-control  w-full px-4 py-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                :placeholder="$t('word55')" />
            </div>
            
            <div v-show="reset_status == 2" class="w-full relative">
                <input :type="password_type" v-model="reset_password"
                class=" form-control mt-5 w-full px-4 py-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                :placeholder="$t('word133')"/>
                <svg @click="password_show = false, password_type = 'password'" v-if="password_show" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-7 absolute" style="top: 45%; right: 15px"><path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" /></svg>
                <svg @click="password_show = true, password_type = 'text'" v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-7 absolute" style="top: 45%; right: 15px"><path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" /><path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" /></svg>
            </div>
            
            <div v-show="reset_status == 2" class="flex justify-between items-center w-full my-4 ">
                <span class="text-gray-500">{{$t('word60')}}</span>
                <span v-if="reset_sms_seconds > 0" class="text-gray-900 font-medium text-xl">{{ reset_sms_seconds }}</span>
                <span v-else @click="resetSmsSend" class="text-gray-900 font-medium text-xl">{{$t('word61')}}</span>
            </div>

            <button v-if="reset_status == 1" @click="resetCheck()" :disabled="reset_btn" type="button" class="mt-5 border border-zinc-200 w-full p-3 font-medium text-xl rounded-lg bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white flex items-center justify-center">
                {{$t('word54')}}
                <svg v-show="reset_btn" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="animate-spin w-5 h-5 ml-3"><path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" /></svg>
            </button>
            <button v-else @click="resetComplete()" :disabled="reset_btn_complete" type="button" class=" border border-zinc-200 w-full p-3 font-medium text-xl rounded-lg bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white flex items-center justify-center">
                {{$t('word103')}}
                <svg v-show="reset_btn_complete" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="animate-spin w-5 h-5 ml-3"><path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" /></svg>
            </button>
            <button v-if="reset_status == 1" @click="$router.push({ name: 'login' })" type="button" class="mt-4 border border-zinc-200 w-full p-2 font-medium text-lg rounded-lg bg-gradient-to-r from-gray-400 via-gray-500 to-gray-500  text-white">Вернутся</button>
            <button v-else @click="reset_status = 1; reset_error = 0" type="button" class="mt-4 border border-zinc-200 w-full p-2 font-medium text-lg rounded-lg bg-gradient-to-r from-gray-400 via-gray-500 to-gray-500  text-white">Назад</button>
            <br><br><br>
        </div>

   <Navigator />
  
</template>

<script>

import  Navigator  from '@/components/Navigator.vue'

let x;
export default ({
    components: {Navigator},

    data() {
        return {
            background_dark: false,

            reset_status: 1,
            password_show: false,
            password_type: 'password',
            reset_error: 0,
            reset_btn: false,
            reset_btn_complete: false,
            reset_phone: "",
            reset_phone_temp: localStorage.getItem('resetPhoneTemp') ? localStorage.getItem('resetPhoneTemp') : "",
            reset_password: "",
            reset_code: "",
            reset_sms_seconds: localStorage.getItem('resetSeconds') ? localStorage.getItem('resetSeconds') : 120,
        }
    },
    mounted() {
        
    },
    updated() {
        if (this.$store.state.auth) {
                this.$router.push({ name: 'profile' })
            }
    },
    methods: {
       resetCheck() {
            this.reset_btn = true
            this.reset_error = 0
            if (this.reset_phone.length == this.$store.state.vendor.phone_length) {
                if (navigator.onLine) {
                    this.reset_status = 2;
                    this.reset_error = 0
                    if (this.reset_phone_temp != this.reset_phone) {
                        this.reset_sms_seconds = 120
                        fetch(`${this.$store.state.url}/login/reset/send/`, {
                            method: "POST",
                            headers: {"Content-Type": "application/json",Accept: "application/json"},
                            body: JSON.stringify({
                                phone: this.reset_phone,
                                code: this.$store.state.vendor_code,
                                }),
                            })
                            this.reset_btn = false
                    } else if (this.reset_sms_seconds < 0 || this.reset_sms_seconds == 120) {
                        this.reset_sms_seconds = 120
                        fetch(`${this.$store.state.url}/login/reset/send/`, {
                            method: "POST",
                            headers: {"Content-Type": "application/json",Accept: "application/json"},
                            body: JSON.stringify({
                                phone: this.reset_phone,
                                code: this.$store.state.vendor_code,
                                }),
                            }) 
                            this.reset_btn = false
                    }
                    clearInterval(x)
                    x = setInterval(()=>{this.reset_sms_seconds--;localStorage.setItem('resetSeconds', this.reset_sms_seconds);if (this.reset_sms_seconds < 0) {clearInterval(x)}},1e3)
                    this.reset_phone_temp = this.reset_phone
                    localStorage.setItem('resetPhoneTemp', this.reset_phone_temp)
                    this.reset_btn = false
                } else {
                    this.reset_error = 3
                    this.reset_btn = false
                }
            } else {
                this.reset_error = 2
                this.reset_btn = false
            }
            
        },
        resetComplete() {
            this.reset_btn_complete = true
            this.reset_error = 0
            if (this.reset_code.length && this.reset_password.length) {
                if (this.reset_password.length > 5) {
                    if (navigator.onLine) {
                        fetch(`${this.$store.state.url}/login/reset/check/`, {
                            method: "POST",
                            headers: {"Content-Type": "application/json",Accept: "application/json"},
                            body: JSON.stringify({
                                phone: this.reset_phone,
                                code: this.$store.state.vendor_code,
                                sms: this.reset_code,
                                password: this.reset_password
                                }),
                            })
                            .then((e) => e.json())
                            .then((e) => {
                                if (200 == e.status) {
                                    this.$store.state.login_message = 888
                                    this.reset_status = 1
                                    this.reset_error = 0
                                    this.reset_btn = false
                                    this.reset_phone = ""
                                    this.reset_password = ""
                                    this.reset_code = ""
                                    this.reset_phone_temp = ""
                                    localStorage.setItem("resetSeconds", this.reset_phone_temp)
                                    localStorage.removeItem("resetPhoneTemp")
                                    this.$router.push({ name: 'login' })   
                                }
                                else {
                                    this.reset_error = 5
                                }
                                this.reset_btn_complete = false
                            })
                           
                    } else {
                        this.reset_error = 3
                        this.reset_btn_complete = false
                    }
                } else {
                    this.reset_error = 6
                    this.reset_btn_complete = false
                }
            } else {
                this.reset_error = 1
                this.reset_btn_complete = false
            }
        }, 
        resetSmsSend() {
            this.reset_error = 999
            this.reset_sms_seconds = 120
            localStorage.setItem('resetSeconds', this.reset_sms_seconds)
            clearInterval(x)
            x = setInterval(()=>{this.reset_sms_seconds--;localStorage.setItem('resetSeconds', this.reset_sms_seconds);if (this.reset_sms_seconds < 0) {clearInterval(x);}},1e3)
            fetch(`${this.$store.state.url}/login/reset/send/`, {
                method: "POST",
                headers: {"Content-Type": "application/json",Accept: "application/json"},
                body: JSON.stringify({
                    phone: this.reset_phone,
                    code: this.$store.state.vendor_code,
                    }),
                })  
        }
    }
    
})
</script>   

<style lang="scss">
    
</style>