import { createStore } from "vuex";

export default createStore({
  state: {
    // DEFAULT DATA
    loading: true,
    not_found: false,
    expired: false,
    url: "https://my.yola.menu/api",
    vendor_code: "",
    current_lang: localStorage.getItem('currentLang') ? localStorage.getItem('currentLang') : 'ru',

    // AUTH
    auth: false,
    auth_token: localStorage.getItem('authToken') ? localStorage.getItem('authToken') : "", 
    user_name: "",
    user_temp_name: "",
    user_phone: "",

    // VENDORS DATA
    vendor: {},
    today: {},
    sliders: [],
    categories: [],
    categories_nav: [],
    addresses: [],
    orders: [],

    // Slider
    slider: {},

    // Product
    product: {},
    product_size: {},

    // DELIVERY
    delivery_flag: false,
    min_delivery: 0,
    work_time: false,

    // CART
    cart: [],
    cart_detail: [],

    // ACCOUNT CURRENT
    account_current_year: "",
    account_current_month: "",
    account_current_month_code: "",
    account_current_day: "",
    
    // ACCOUNT TEMP
    account_temp_year: "",
    account_temp_month: "",
    account_temp_month_code: "",
    account_temp_day: "",

    // LANGS ALLOW
    lang_ru: false,
    lang_uz: false,
    lang_en: false,
    lang_tr: false,
    lang_cn: false,
    lang_kz: false,
    lang_kg: false,
    lang_ko: false,
    lang_ar: false,
    lang_hi: false,
    lang_tj: false,
    lang_es: false,

    // DAYS
    reg_month_days: [],
    days_31: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ],
    days_30: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30,
    ],
    days_28: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28,
    ],
    years: [2012, 2011, 2010, 2009, 2008, 2007, 2006, 2005, 2004, 2003, 2002, 2001, 2000, 1999, 1998, 1997, 1996, 1995, 1994, 1993, 1992, 1991, 1990, 1989, 1988, 1987, 1986, 1985, 1984, 1983, 1982, 1981, 1980, 1979, 1978, 1977, 1976, 1975, 1974, 1973, 1972, 1971, 1970, 1969, 1968, 1967, 1966, 1965, 1964, 1963, 1962, 1961, 1960, 1959, 1958, 1957, 1956, 1955, 1954, 1953, 1952, 1951, 1950],



    // COMPONENTS
    category_open: false,
    action_open: false,
    lang_open: false,
    background_dark: false,
    login_message: 0,
    not_found: false
  },
  getters: {
    cartDetailQuantity: state => {
      let cP = state.cart.find(item => item.id == state.product_size.id && item.size == state.product_size.size)
      if (cP) {
        return cP.quantity
      } else {
        return 0
      }
    },
    cartTotalQuantity: state => state.cart.reduce((t, {quantity}) => t + quantity, 0)
    
  },
  mutations: {
    productAddQuantity(state) {
        state.cart.push({
          id: state.product_size.id,
          size: state.product_size.size,
          title: state.product_size.title,
          price: state.product_size.price,
          show_price: state.product_size.show_price,
          quantity: 1,
          image: state.product.image_min
      })
    },
    productPlusQuantity(state) {
      let qP = state.cart.find(item => item.id == state.product_size.id && item.size == state.product_size.size)
      qP.quantity++
    },
    productMinusQuantity(state) {
      let qP = state.cart.find(item => item.id == state.product_size.id && item.size == state.product_size.size)
      qP.quantity--
      if (qP.quantity == 0) {
        let x = state.cart.find(item => item.id == state.product_size.id && item.size == state.product_size.size)
        state.cart = state.cart.filter(item => item != x)
      }
    },
    changeProductSize(state, id) {
      if (id) {
        let pQ = state.product.sizes.find(item => item.id == id)
        let cQ = state.cart.find(item => item.id == state.product_size.id && item.size == id)
        if (cQ) {
            state.product_size = {
                id: state.product.id,
                size: pQ.id,
                title: pQ.title,
                price: pQ.price,
                show_price: pQ.show_price,
            }
        } else {
            state.product_size = {
                id: state.product.id,
                size: pQ.id,
                title: pQ.title,
                price: pQ.price,
                show_price: pQ.show_price,
            }
        }
      } 
      else {
          state.product_size = {
              id: state.product.id,
              size: 0,
              title: state.product.title,
              price: state.product.price,
              show_price: state.product.show_price,
          }
      } 
    },
    cartPlusQuantity(state, pr) {
      let qP = state.cart.find(item => item.id == pr.pid && item.size == pr.sid)
      qP.quantity++
    },
    cartMinusQuantity(state, pr) {
      let qP = state.cart.find(item => item.id == pr.pid && item.size == pr.sid)
      qP.quantity--
      if (qP.quantity == 0) {
        let x = state.cart.find(item => item.id == pr.pid && item.size == pr.sid)
        state.cart = state.cart.filter(item => item != x)
      }
    },
    cartTrash(state, pr) {
      let x = state.cart.find(item => item.id == pr.pid && item.size == pr.sid)
      state.cart = state.cart.filter(item => item != x)
    },
    setVendorCode(state, vendor_code) {
      state.vendor_code = vendor_code;
    },
    addNewAddress(state, addr) {
      state.addresses.push(addr)
    },
    addNewOrder(state, order) {
      state.addresses.push(order)
    },
    setCurrentLang(state, current_lang) {
      state.current_lang = current_lang;
    },
    selectCurrentCategory(state, category_id) {
      state.categories_nav.forEach(item => {item.active = false})
      state.categories_nav.find(item => item.id == category_id).active = true
    },
    setCurrentSlider(state, id) {
      state.slider = state.sliders.find(item => item.id == id)
    },
    setCurrentProduct(state, id) {
      state.categories.forEach( (item)=> {
        item.products.forEach((i) => {
          if (i.id == id) {
            state.product = i
          }
        })
      })
    },
    toggleCategoryOpen(state) {
      state.category_open = !state.category_open;
      state.background_dark = !state.background_dark;
    },
    toggleActionOpen(state) {
      state.action_open = !state.action_open;
      state.background_dark = !state.background_dark;
    },
    toggleLangOpen(state) {
      state.lang_open = !state.lang_open;
      state.background_dark = !state.background_dark;
    },
    selectDay(state, day) {
      state.today = state.vendor.workdays.find((item) => item.day == day);
    },
    setAuth(state, e) {
      state.addresses = e.account.address;
      state.orders = e.account.orders;
      localStorage.setItem('authToken', e.token)
      state.auth_token = e.token;
      state.auth = true;
      state.user_name = e.account.fio;
      state.user_temp_name = e.account.fio;
      state.user_phone = e.account.phone;
      
      // ACCOUNT
      try {
        state.account_current_year = e.account.birthday.split("-")[0];
        state.account_current_month_code = e.account.birthday.split("-")[1];
        state.account_current_day = e.account.birthday.split("-")[2];
        state.account_temp_year = e.account.birthday.split("-")[0];
        state.account_temp_month_code = e.account.birthday.split("-")[1];
        state.account_temp_day = e.account.birthday.split("-")[2];
        switch (Number(state.account_current_month_code)) {
          case 1:
            state.reg_month_days = state.days_31;
            state.account_current_month = "Январь";
            state.account_temp_month = "Январь";
            break;
          case 2:
            state.reg_month_days = state.days_28;
            state.account_current_month = "Февраль";
            state.account_temp_month = "Февраль";
            break;
          case 3:
            state.reg_month_days = state.days_31;
            state.account_current_month = "Март";
            state.account_temp_month = "Март";
            break;
          case 4:
            state.reg_month_days = state.days_30;
            state.account_current_month = "Апрель";
            state.account_temp_month = "Апрель";
            break;
          case 5:
            state.reg_month_days = state.days_31;
            state.account_current_month = "Май";
            state.account_temp_month = "Май";
            break;
          case 6:
            state.reg_month_days = state.days_30;
            state.account_current_month = "Июнь";
            state.account_temp_month = "Июнь";
            break;
          case 7:
            state.reg_month_days = state.days_31;
            state.account_current_month = "Июль";
            state.account_temp_month = "Июль";
            break;
          case 8:
            state.reg_month_days = state.days_31;
            state.account_current_month = "Август";
            state.account_temp_month = "Август";
            break;
          case 9:
            state.reg_month_days = state.days_30;
            state.account_current_month = "Сентябрь";
            state.account_temp_month = "Сентябрь";
            break;
          case 10:
            state.reg_month_days = state.days_31;
            state.account_current_month = "Октябрь";
            state.account_temp_month = "Октябрь";
            break;
          case 11:
            state.reg_month_days = state.days_30;
            state.account_current_month = "Ноябрь";
            state.account_temp_month = "Ноябрь";
            break;
          case 12:
            state.reg_month_days = state.days_31;
            state.account_current_month = "Декабрь";
            state.account_temp_month = "Декабрь";
        }
        
      } catch (e) {state.reg_month_days = state.days_31;}
    }
  },
  actions: {
    async GET_VENDOR_FROM_API({ state, commit }) {
      
      return await fetch(`${state.url}/get/`, {
        method: "POST",
        headers: {"Content-Type": "application/json",Accept: "application/json",},
        body: JSON.stringify({
          code: state.vendor_code,
          lang: state.current_lang,
          token: state.auth_token,
            })
        })
        .then((response) => response.json())

        .then((response)=>{
          const e = response;
          state.not_found = false
          if (e.status == 123) {
            state.expired = true;
          } else {
            
            // VENDOR
            state.vendor = e.vendor;
            state.sliders = e.sliders;
            state.categories = e.categories;
            state.categories_nav = e.categories.map((item) => ({
              title: item.title,
              id: item.id,
              active: false,
            }));
            try {state.categories_nav[0].active = true} catch(e) {}
            
            // DELIVERY
            state.delivery_flag = e.vendor.delivery;
            state.min_delivery = e.vendor.min_delivery;

            if (new Date().getDay() == 0) {
              state.today = e.vendor.workdays.find((item) => item.day == 7);
            } else {
              state.today = e.vendor.workdays.find(
                (item) => item.day == new Date().getDay()
              );
            }
            
            if (state.today.work) {
                state.work_time = state.today.work
                // let nT = new Date().getHours()
                // let oT = state.today.open.slice(0,2)
                // let cT = state.today.close.slice(0,2)

                // function workTime(e,n,r){let t=!1;return(e=>n)&&(r>n?e<r&&e>=n&&(t=!0):e>=n?t=!0:e<r&&(t=!0)),t}
                // state.work_time = workTime(nT, oT, cT)
            }
            
            // ACCOUNT
            try {
              state.account_current_year = e.account.birthday.split("-")[0];
              state.account_current_month_code = e.account.birthday.split("-")[1];
              state.account_current_day = e.account.birthday.split("-")[2];
              state.account_temp_year = e.account.birthday.split("-")[0];
              state.account_temp_month_code = e.account.birthday.split("-")[1];
              state.account_temp_day = e.account.birthday.split("-")[2];
              switch (Number(state.account_current_month_code)) {
                case 1:
                  state.reg_month_days = state.days_31;
                  state.account_current_month = "Январь";
                  state.account_temp_month = "Январь";
                  break;
                case 2:
                  state.reg_month_days = state.days_28;
                  state.account_current_month = "Февраль";
                  state.account_temp_month = "Февраль";
                  break;
                case 3:
                  state.reg_month_days = state.days_31;
                  state.account_current_month = "Март";
                  state.account_temp_month = "Март";
                  break;
                case 4:
                  state.reg_month_days = state.days_30;
                  state.account_current_month = "Апрель";
                  state.account_temp_month = "Апрель";
                  break;
                case 5:
                  state.reg_month_days = state.days_31;
                  state.account_current_month = "Май";
                  state.account_temp_month = "Май";
                  break;
                case 6:
                  state.reg_month_days = state.days_30;
                  state.account_current_month = "Июнь";
                  state.account_temp_month = "Июнь";
                  break;
                case 7:
                  state.reg_month_days = state.days_31;
                  state.account_current_month = "Июль";
                  state.account_temp_month = "Июль";
                  break;
                case 8:
                  state.reg_month_days = state.days_31;
                  state.account_current_month = "Август";
                  state.account_temp_month = "Август";
                  break;
                case 9:
                  state.reg_month_days = state.days_30;
                  state.account_current_month = "Сентябрь";
                  state.account_temp_month = "Сентябрь";
                  break;
                case 10:
                  state.reg_month_days = state.days_31;
                  state.account_current_month = "Октябрь";
                  state.account_temp_month = "Октябрь";
                  break;
                case 11:
                  state.reg_month_days = state.days_30;
                  state.account_current_month = "Ноябрь";
                  state.account_temp_month = "Ноябрь";
                  break;
                case 12:
                  state.reg_month_days = state.days_31;
                  state.account_current_month = "Декабрь";
                  state.account_temp_month = "Декабрь";
                
              }
              
            } catch (e) {state.reg_month_days = state.days_31;}

            // AUTH
            if (e.account.auth) {
              state.auth = true;
              state.user_name = e.account.fio;
              state.user_temp_name = e.account.fio;
              state.user_phone = e.account.phone;
              state.orders = e.account.orders;
              state.addresses = e.account.address;
            }

            // LANGS
            state.lang_ru = e.vendor.avl.ru;
            state.lang_uz = e.vendor.avl.uz;
            state.lang_en = e.vendor.avl.en;
            state.lang_tr = e.vendor.avl.tr;
            state.lang_cn = e.vendor.avl.cn;
            state.lang_kz = e.vendor.avl.kz;
            state.lang_kg = e.vendor.avl.kg;
            state.lang_ko = e.vendor.avl.ko;
            state.lang_ar = e.vendor.avl.ar;
            state.lang_hi = e.vendor.avl.hi;
            state.lang_tj = e.vendor.avl.tj;
            state.lang_es = e.vendor.avl.es;

            
            if (window.location.pathname.split('/p/')[1]) {
                state.categories.forEach( (item)=> {
                  item.products.forEach((i) => {
                    if (i.id == window.location.pathname.split('/p/')[1]) {
                      state.product = i
                    }
                  })
                })
                
                
                state.product_size = {
                    id: state.product.id,
                    size: 0,
                    title: state.product.title,
                    price: state.product.price,
                    show_price: state.product.show_price,
                }
                if (state.product_size.id == undefined) {
                  state.not_found = true
                }
              }
            if (window.location.pathname.split('/s/')[1]) {
              state.slider = state.sliders.find(item => item.id == window.location.pathname.split('/s/')[1])  
              if (state.slider == undefined) {
                state.not_found = true
              }
            }
          }

        })

        .catch(function (error) {
          state.not_found = true;
        })
        .finally(function () {
          state.loading = false;
        });
      
    },
  },
  modules: {},
});
