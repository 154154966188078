<template>
    <div class="text-center text-gray-700 px-2 bg-white fixed bottom-0 w-full left-0 z-20" style="box-shadow: 0 0 10px 0 lightgray">
        <div class="grid gap-1 grid-cols-5 wrapper mx-auto">
            <div @click="$router.push({ name: 'vendor' });" class="flex flex-col justify-center items-center"> 
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" :stroke="[$route.name == 'vendor' ? 'rgb(37, 99 ,235, 0.9)' : 'currentColor']" class="w-6"><path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" /></svg>
                <p class="text-xs font-medium pt-1" :class="{'text-blue-600': $route.name == 'vendor'}">{{$t('word34')}}</p>
            </div>
            <div  @click="$router.push({ name: 'about' }); delScroll()"  class="flex flex-col justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" :stroke="[$route.name == 'about' ? 'rgb(37, 99 ,235, 0.9)' : 'currentColor']" class="w-6"><path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" /></svg>
                <p class="text-xs font-medium pt-1" :class="{'text-blue-600': $route.name == 'about'}">{{$t('word35')}}</p>
            </div>
            <div @click="$store.commit('toggleLangOpen'); delScroll()" class="flex flex-col justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6"><path stroke-linecap="round" stroke-linejoin="round" d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" /></svg>
                <p class="text-xs font-medium pt-1">{{$t('word36')}}</p>
            </div>
            <div  @click="$router.push({ name: 'cart' }); delScroll()" class="flex flex-col justify-center items-center">
                <div class="flex">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"  :stroke="[ ['cart', 'checkout'].includes($route.name) ? 'rgb(37, 99 ,235, 0.9)' : 'currentColor']"  class="w-6 pr-1"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" /></svg>
                    <small class="font-bold text-red-700">{{ $store.getters.cartTotalQuantity }}</small>
                </div>
                <p class="text-xs font-medium pt-1" :class="{'text-blue-600': ['cart', 'checkout'].includes($route.name)}">{{$t('word37')}}</p>
            </div>
            <div @click="$store.state.auth ? $router.push({ name: 'profile' }) : $router.push({ name: 'login' }); delScroll()"  class="flex flex-col justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" :stroke="[ ['login', 'reset', 'register', 'profile'].includes($route.name) ? 'rgb(37, 99 ,235, 0.9)' : 'currentColor']" class="w-6"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" /></svg>
                <p class="text-xs font-medium pt-1" :class="{'text-blue-600': ['login', 'reset', 'register', 'profile'].includes($route.name)}">{{$t('word38')}}</p>
            </div>
        </div>
    </div>

    <div  class="rounded-t-lg bg-white fixed w-full z-30 pt-3 hidden" :class="{'modal_view' : $store.state.lang_open }">
        <div class="flex justify-center py-2"><div style="height: 3px; width:60px; background-color: #999"></div></div>
        <div class="wrapper mx-auto">
            <div class="flex justify-between items-center">
                <p class="text-lg font-medium">{{$t('word36')}}</p>
                <svg @click="$store.commit('toggleLangOpen')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#666" class="w-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" /></svg>
            </div>
            <div class=" mt-4" style="height: 50vh; overflow: auto">
                <button @click="selectLang('ru')" :disabled="$store.state.current_lang == 'ru'"  type="button" class="w-full flex items-center justify-between text-gray-900 bg-white border border-zinc-200 mb-2 p-3 text-lg font-medium rounded-lg" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': $store.state.current_lang == 'ru'}"><img src="@/assets/flags/ru.png" class="mr-2 w-7" alt="">Русский язык</button>
                <button @click="selectLang('uz')" :disabled="$store.state.current_lang == 'uz'"  type="button" class="w-full flex items-center justify-between text-gray-900 bg-white border border-zinc-200 mb-2 p-3 text-lg font-medium rounded-lg" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': $store.state.current_lang == 'uz'}"><img src="@/assets/flags/uz.png" class="mr-2 w-7" alt="">O'zbek tili</button>
                <button @click="selectLang('en')" :disabled="$store.state.current_lang == 'en'"  type="button" class="w-full flex items-center justify-between text-gray-900 bg-white border border-zinc-200 mb-2 p-3 text-lg font-medium rounded-lg" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': $store.state.current_lang == 'en'}"><img src="@/assets/flags/en.png" class="mr-2 w-7" alt="">English language</button>
                <button @click="selectLang('tr')" :disabled="$store.state.current_lang == 'tr'"  type="button" class="w-full flex items-center justify-between text-gray-900 bg-white border border-zinc-200 mb-2 p-3 text-lg font-medium rounded-lg" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': $store.state.current_lang == 'tr'}"><img src="@/assets/flags/tr.png" class="mr-2 w-7" alt="">Türkçe</button>
                <button @click="selectLang('kz')" :disabled="$store.state.current_lang == 'kz'"  type="button" class="w-full flex items-center justify-between text-gray-900 bg-white border border-zinc-200 mb-2 p-3 text-lg font-medium rounded-lg" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': $store.state.current_lang == 'kz'}"><img src="@/assets/flags/kz.png" class="mr-2 w-7" alt="">Қазақ тілі</button>
                <button @click="selectLang('kg')" :disabled="$store.state.current_lang == 'kg'"  type="button" class="w-full flex items-center justify-between text-gray-900 bg-white border border-zinc-200 mb-2 p-3 text-lg font-medium rounded-lg" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': $store.state.current_lang == 'kg'}"><img src="@/assets/flags/kg.png" class="mr-2 w-7" alt="">Кыргыз тили</button>
                <button @click="selectLang('tj')" :disabled="$store.state.current_lang == 'tj'"  type="button" class="w-full flex items-center justify-between text-gray-900 bg-white border border-zinc-200 mb-2 p-3 text-lg font-medium rounded-lg" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': $store.state.current_lang == 'tj'}"><img src="@/assets/flags/tj.png" class="mr-2 w-7" alt="">Забони тоҷикӣ</button>
                <button @click="selectLang('cn')" :disabled="$store.state.current_lang == 'cn'"  type="button" class="w-full flex items-center justify-between text-gray-900 bg-white border border-zinc-200 mb-2 p-3 text-lg font-medium rounded-lg" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': $store.state.current_lang == 'cn'}"><img src="@/assets/flags/cn.png" class="mr-2 w-7" alt="">中国语文科</button>
                <button @click="selectLang('ko')" :disabled="$store.state.current_lang == 'ko'"  type="button" class="w-full flex items-center justify-between text-gray-900 bg-white border border-zinc-200 mb-2 p-3 text-lg font-medium rounded-lg" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': $store.state.current_lang == 'ko'}"><img src="@/assets/flags/ko.png" class="mr-2 w-7" alt="">한국어</button>
                <button @click="selectLang('es')" :disabled="$store.state.current_lang == 'es'"  type="button" class="w-full flex items-center justify-between text-gray-900 bg-white border border-zinc-200 mb-2 p-3 text-lg font-medium rounded-lg" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': $store.state.current_lang == 'es'}"><img src="@/assets/flags/es.png" class="mr-2 w-7" alt="">Español</button>
                <button @click="selectLang('ar')" :disabled="$store.state.current_lang == 'ar'"  type="button" class="w-full flex items-center justify-between text-gray-900 bg-white border border-zinc-200 mb-2 p-3 text-lg font-medium rounded-lg" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': $store.state.current_lang == 'ar'}">اللغة العربية <img src="@/assets/flags/ar.png" class="mr-2 w-7" alt=""></button>
                <button @click="selectLang('hi')" :disabled="$store.state.current_lang == 'hi'"  type="button" class="w-full flex items-center justify-between text-gray-900 bg-white border border-zinc-200 mb-2 p-3 text-lg font-medium rounded-lg" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': $store.state.current_lang == 'hi'}"><img src="@/assets/flags/hi.png" class="mr-2 w-7" alt="">भारतीय भाषा</button>
                <br>
            </div>
        </div>
    </div>
</template>

<script>
export default ({
    data() {
        return {}
    },
    methods: {
        selectLang(lang) {
            localStorage.setItem('currentLang', lang)
            location.reload();
        },
        delScroll() {
            localStorage.removeItem('scroll')
        }
    }
    
})
</script>

<style>

</style>