<template>
    
    <div :class="[reg_birthday_page ? 'background_dark' : 'background_dark_none']"  class="h-screen w-full fixed top-0 left-0 right-0 z-20"></div>

    <div class="wrapper mx-auto flex flex-col justify-center items-center">
            <img class="w-6/12 mt-3" :src="$store.state.vendor.logo" alt="">
            <p class="mt-4 mb-1 text-3xl">{{$t('word97')}}</p>
            <p v-show="reg_status == 2" class="text-gray-600 text-sm"> {{$t('word56')}} <b>+{{ $store.state.vendor.phone_code }} {{ reg_phone }}</b></p>
            <div class="mb-4"></div>
            <div v-if="reg_error == 1" class="bg-red-600 text-white w-full text-sm rounded font-medium p-3 text-center mb-3">
                <span>{{$t('word63')}}</span>
            </div>
            <div v-else-if="reg_error == 2" class="bg-red-600 text-white w-full text-sm rounded font-medium p-3 text-center mb-3">
                <span>{{$t('word53')}}</span>
            </div>
            <div v-else-if="reg_error == 3" class="bg-red-600 text-white w-full text-sm rounded font-medium p-3 text-center mb-3">
                <span>{{$t('word145')}}</span>
            </div>
            <div v-else-if="reg_error == 4" class="bg-red-600 text-white w-full text-sm rounded font-medium p-3 text-center mb-3">
                <span>{{$t('word105')}}</span>
            </div>
            <div v-else-if="reg_error == 5" class="bg-red-600 text-white w-full text-sm rounded font-medium p-3 text-center mb-3">
                <span>{{$t('word58')}}</span>
            </div>
            <div v-else-if="reg_error == 6" class="bg-red-600 text-white w-full text-sm rounded font-medium p-3 text-center mb-3">
                <span>{{$t('word100')}}</span>
            </div>
            <div v-else-if="reg_error == 999" class="bg-green-600 text-white w-full text-sm rounded font-medium p-3 text-center mb-3">
                <span>{{$t('word59')}}</span>
            </div>
            
            <div v-show="reg_status == 1" class="w-full relative">
                <input type="text" v-model="reg_phone"
                class="form-control w-full py-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                :placeholder="$t('word52')" style="padding-left: 70px"/>
                <span class="absolute text-xl font-normal text-gray-500" style="top: 24.6%; left: 12px">+{{ $store.state.vendor.phone_code }}</span>
            </div>

            <div v-show="reg_status == 1" class="w-full ">
                <input type="text" v-model="reg_name"
                class="form-control mt-5 px-4 w-full py-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                :placeholder="$t('word21')" />
            </div>

            <div v-show="reg_status == 1" class="w-full relative">
                <input :type="password_type" v-model="reg_password"
                class=" form-control mt-5 w-full px-4 py-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                :placeholder="$t('word136')"/>
                <svg @click="password_show = false, password_type = 'password'" v-if="password_show" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-7 absolute" style="top: 45%; right: 15px"><path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" /></svg>
                <svg @click="password_show = true, password_type = 'text'" v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-7 absolute" style="top: 45%; right: 15px"><path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" /><path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" /></svg>
            </div>

            <div v-show="reg_status == 1" class="mt-5" style="display: grid; grid-template-columns: repeat(3, 1fr); grid-gap: 7px">
                <input @click="showDay()" readonly type="text" v-model="reg_day" class=" form-control w-full px-4 py-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition" :placeholder="$t('word106')"/>
                <input @click="showMonth()" readonly type="text" v-model="reg_month" class=" form-control w-full px-4 py-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition" :placeholder="$t('word107')"/>
                <input @click="showYear()" readonly type="text" v-model="reg_year" class=" form-control w-full px-4 py-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition" :placeholder="$t('word108')"/>
            </div>

            <p v-show="reg_status == 1" style="font-size: 10px" class="text-center text-gray-700 mt-4">
                {{$t('word152')}} <br>
                <a class="text-blue-600" target="_blank" href="https://my.yola.menu/media/agreement.pdf">{{ $t('word110') }}</a>
            </p>

            <div v-show="reg_status == 2" class="w-full relative">
                <input type="tel" v-model="reg_code"
                class=" form-control mt-1 w-full px-4 py-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                :placeholder="$t('word55')"/></div>

            <div v-show="reg_status == 2" class="flex justify-between items-center w-full my-4 ">
                <span class="text-gray-500">{{ $t('word60') }}</span>
                <span v-if="reg_sms_seconds > 0" class="text-gray-900 font-medium text-xl">{{ reg_sms_seconds }}</span>
                <span v-else @click="regSmsSend" class="text-gray-900 font-medium text-xl">{{ $t('word61') }}</span>
            </div>

            <button v-if="reg_status == 1" @click="regCheck()" :disabled="reg_btn" type="button" class="mt-5 border border-zinc-200 w-full p-3 font-medium text-xl rounded-lg bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white flex items-center justify-center">
                {{ $t('word54') }}
                <svg v-show="reg_btn" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="animate-spin w-5 h-5 ml-3"><path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" /></svg>
            </button>
            <button v-else @click="regComplete()" :disabled="reg_btn_complete" type="button" class="mt-5 border border-zinc-200 w-full p-3 font-medium text-xl rounded-lg bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white flex items-center justify-center">
               {{ $t('word97') }}
                <svg v-show="reg_btn_complete" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="animate-spin w-5 h-5 ml-3"><path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" /></svg>
            </button>
            <button v-if="reg_status == 1" @click="$router.push({ name: 'login' })" type="button" class="mt-4 border border-zinc-200 w-full p-2 font-medium text-lg rounded-lg bg-gradient-to-r from-gray-400 via-gray-500 to-gray-500  text-white">{{ $t('word150') }}</button>
            <button v-else @click="reg_status = 1; reg_error = 0" type="button" class="mt-4 border border-zinc-200 w-full p-2 font-medium text-lg rounded-lg bg-gradient-to-r from-gray-400 via-gray-500 to-gray-500  text-white">{{ $t('word104') }}</button>
            <br><br><br>
        </div>

   <Navigator />

   <div class="rounded-t-lg bg-white fixed w-full z-30 pt-3 hidden" :class="{'modal_view' : reg_birthday_page == 1 }">
        <div class="flex justify-center py-2"><div style="height: 3px; width:60px; background-color: #999"></div></div>
        <div class="wrapper mx-auto">
            <div class="flex justify-between items-center">
                <p class="text-lg font-medium">{{ $t('word111') }}</p>
                <svg @click="closeModal()" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#666" class="w-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" /></svg>
            </div>
            <div class=" mt-4" style="height: 50vh; overflow: auto">
                <button @click="selectDay(c)" v-for="c, i in months" v-bind:key="i"  type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-2 font-medium rounded-lg text-xl" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': c == reg_day}">{{ c }}</button>
                <br>
            </div>
        </div>
    </div>

    <div class="rounded-t-lg bg-white fixed w-full z-30 pt-3 hidden" :class="{'modal_view' : reg_birthday_page == 2 }">
        <div class="flex justify-center py-2"><div style="height: 3px; width:60px; background-color: #999"></div></div>
        <div class="wrapper mx-auto">
            <div class="flex justify-between items-center">
                <p class="text-lg font-medium">{{ $t('word112') }}</p>
                <svg @click="closeModal()" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#666" class="w-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" /></svg>
            </div>
            <div class=" mt-4" style="height: 50vh; overflow: auto">
                <button @click="selectMonth(1)" type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-2 font-medium rounded-lg text-xl" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': reg_month_code == 1}"> {{ $t('word114') }}</button>
                <button @click="selectMonth(2)" type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-2 font-medium rounded-lg text-xl" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': reg_month_code == 2}"> {{ $t('word115') }}</button>
                <button @click="selectMonth(3)" type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-2 font-medium rounded-lg text-xl" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': reg_month_code == 3}"> {{ $t('word116') }}</button>
                <button @click="selectMonth(4)" type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-2 font-medium rounded-lg text-xl" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': reg_month_code == 4}"> {{ $t('word117') }}</button>
                <button @click="selectMonth(5)" type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-2 font-medium rounded-lg text-xl" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': reg_month_code == 5}"> {{ $t('word118') }}</button>
                <button @click="selectMonth(6)" type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-2 font-medium rounded-lg text-xl" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': reg_month_code == 6}"> {{ $t('word119') }}</button>
                <button @click="selectMonth(7)" type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-2 font-medium rounded-lg text-xl" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': reg_month_code == 7}"> {{ $t('word120') }}</button>
                <button @click="selectMonth(8)" type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-2 font-medium rounded-lg text-xl" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': reg_month_code == 8}"> {{ $t('word121') }}</button>
                <button @click="selectMonth(9)" type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-2 font-medium rounded-lg text-xl" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': reg_month_code == 9}"> {{ $t('word122') }}</button>
                <button @click="selectMonth(10)" type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-2 font-medium rounded-lg text-xl" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': reg_month_code == 10}"> {{ $t('word123') }}</button>
                <button @click="selectMonth(11)" type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-2 font-medium rounded-lg text-xl" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': reg_month_code == 11}"> {{ $t('word124') }}</button>
                <button @click="selectMonth(12)" type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-2 font-medium rounded-lg text-xl" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': reg_month_code == 12}"> {{ $t('word125') }}</button>
                <br>
            </div>
        </div>
    </div>

    <div class="rounded-t-lg bg-white fixed w-full z-30 pt-3 hidden" :class="{'modal_view' : reg_birthday_page == 3 }">
        <div class="flex justify-center py-2"><div style="height: 3px; width:60px; background-color: #999"></div></div>
        <div class="wrapper mx-auto">
            <div class="flex justify-between items-center">
                <p class="text-lg font-medium">{{ $t('word113') }}</p>
                <svg @click="closeModal()" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#666" class="w-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" /></svg>
            </div>
            <div class=" mt-4" style="height: 50vh; overflow: auto">
                <button @click="selectYear(c)" v-for="c, i in $store.state.years" v-bind:key="i"  type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-2 font-medium rounded-lg text-xl" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': c == reg_year}">{{ c }}</button>
                <br>
            </div>
        </div>
    </div>
</template>

<script>

import  Navigator  from '@/components/Navigator.vue'

let x;
export default ({
    components: {Navigator},

    data() {
        return {
            reg_birthday_page: 0,
            background_dark: false,

            reg_status: 1,
            password_show: false,
            password_type: 'password',
            reg_error: 0,
            reg_btn: false,
            reg_btn_complete: false,
            reg_phone: "",
            reg_name: "",
            reg_password: "",
            reg_code: "",
            reg_sms_seconds: localStorage.getItem('regSeconds') ? localStorage.getItem('regSeconds') : 120,

            reg_year: "",
            reg_month: "",
            reg_month_code: "",
            reg_day: "",

            months: this.$store.state.days_31
        }
    },
    mounted() {
        
    },
    updated() {
        if (this.$store.state.auth) {
                this.$router.push({ name: 'profile' })
            }
    },
    methods: {
        regCheck() {
            this.reg_btn = true
            this.reg_error = 0
            if (this.reg_phone.length && this.reg_name.length && this.reg_password.length && this.reg_year && this.reg_month_code && this.reg_day) {
                if (this.reg_phone.length == this.$store.state.vendor.phone_length) {
                    if (this.reg_password.length > 5) {
                        if (navigator.onLine) {
                            fetch(`${this.$store.state.url}/login/register/send/`, {
                                method: "POST",
                                headers: {"Content-Type": "application/json",Accept: "application/json"},
                                body: JSON.stringify({
                                    phone: this.reg_phone,
                                    code: this.$store.state.vendor_code,
                                    }),
                                })
                                .then((e) => e.json())
                                .then((e) => {
                                    if (200 == e.status) {
                                        this.reg_status =  2
                                        clearInterval(x)
                                        x = setInterval(()=>{this.reg_sms_seconds--;localStorage.setItem('regSeconds', this.reg_sms_seconds);if (this.reg_sms_seconds < 0) {clearInterval(x);}},1e3)
                                    } else {
                                        this.reg_error = 4
                                    }
                                    this.reg_btn = false
                                })
                        } else {
                            this.reg_error = 3
                            this.reg_btn = false
                        }
                    } else {
                        this.reg_error = 6
                        this.reg_btn = false
                    }
                } else {
                    this.reg_error = 2
                    this.reg_btn = false
                }
            } else {
                this.reg_error = 1
                this.reg_btn = false
            }
            
        },
        regComplete() {
            this.reg_btn_complete = true
            this.reg_error = 0
            if (this.reg_code.length) {
                // -------
                    fetch(`${this.$store.state.url}/login/register/check/`, {
                        method: "POST",
                        headers: {"Content-Type": "application/json",Accept: "application/json"},
                        body: JSON.stringify({
                            phone: this.reg_phone,
                            name: this.reg_name,
                            code: this.$store.state.vendor_code,
                            password: this.reg_password,
                            sms: this.reg_code,
                            birthday: `${this.reg_year}-${this.reg_month_code}-${this.reg_day}`
                            }),
                        })
                        .then((e) => e.json())
                        .then((e) => {
                            if (200 == e.status) {
                                this.$store.state.login_message = 777
                                this.reg_phone = ""
                                this.reg_status = 1
                                this.reg_password =  ""
                                this.reg_error= 0
                                this.reg_name= ""
                                this.reg_code= ""
                                this.reg_day= ""
                                this.reg_month = ""
                                this.reg_month_code = ""
                                this.reg_year= ""
                                this.reg_seconds= 120
                                localStorage.setItem("regSeconds",120)
                                this.$router.push({ name: 'login' })   
                            } else {
                                this.reg_error = 5
                            } 
                            this.reg_btn_complete = false
                        })
                        .catch((error) => {
                            location.reload();
                        })
                // ------
            } else {
                this.reg_btn_complete = false
                this.reg_error = 1
            }
        }, 
        regSmsSend() {
            this.reg_error = 999
            this.reg_sms_seconds = 120
            localStorage.setItem('regSeconds', this.reg_sms_seconds)
            clearInterval(x)
            x = setInterval(()=>{this.reg_sms_seconds--;localStorage.setItem('regSeconds', this.reg_sms_seconds);if (this.reg_sms_seconds < 0) {clearInterval(x);}},1e3)
            fetch(`${this.$store.state.url}/login/register/send/`, {
                method: "POST",
                headers: {"Content-Type": "application/json",Accept: "application/json"},
                body: JSON.stringify({
                    phone: this.reg_phone,
                    code: this.$store.state.vendor_code,
                    }),
                })
                .then((e) => e.json())
                .then((e) => {
                    if (200 != e.status) {
                        location.reload();
                    } 
                })
        },

        showDay() {
            this.background_dark = true
            this.reg_birthday_page = 1
        },
        selectDay(day) {
            this.reg_day = day
            this.background_dark = false
            this.reg_birthday_page = 0
        },
        showMonth() {
            this.background_dark = true
            this.reg_birthday_page = 2
        },
        selectMonth(month) {
            this.reg_month_code = month
            this.background_dark = false
            this.reg_birthday_page = 0
            switch (month) {
                case 1:
                    this.reg_month = this.$t('word114');
                    this.months = this.$store.state.days_31
                    break
                case 2:
                    this.reg_month = this.$t('word115');
                    this.months = this.$store.state.days_28;
                    this.reg_day > 28 ? this.reg_day = 28 : "";
                    break
                case 3:
                    this.reg_month =this.$t('word116');
                    this.months = this.$store.state.days_31
                    break
                case 4:
                    this.reg_month = this.$t('word117');
                    this.months = this.$store.state.days_30
                    this.reg_day > 30 ? this.reg_day = 30 : "";
                    break
                case 5:
                    this.reg_month = this.$t('word118');
                    this.months = this.$store.state.days_31
                    break
                case 6:
                    this.reg_month = this.$t('word119');
                    this.months = this.$store.state.days_30
                    this.reg_day > 30 ? this.reg_day = 30 : "";
                    break
                case 7:
                    this.reg_month = this.$t('word120');
                    this.months = this.$store.state.days_31
                    break
                case 8:
                    this.reg_month = this.$t('word121');
                    this.months = this.$store.state.days_31
                    break
                case 9:
                    this.reg_month = this.$t('word122');
                    this.months = this.$store.state.days_30
                    this.reg_day > 30 ? this.reg_day = 30 : "";
                    break
                case 10:
                    this.reg_month = this.$t('word123');
                    this.months = this.$store.state.days_31
                    break
                case 11:
                    this.reg_month = this.$t('word124');
                    this.months = this.$store.state.days_30
                    this.reg_day > 30 ? this.reg_day = 30 : "";
                    break
                case 12:
                    this.reg_month = this.$t('word125');
                    this.months = this.$store.state.days_31
                    break
            }
        },
        showYear() {
            this.background_dark = true
            this.reg_birthday_page = 3
        },
        selectYear(year) {
            this.reg_year = year
            this.background_dark = false
            this.reg_birthday_page = 0
        },
        closeModal() {
            this.background_dark = false
            this.reg_birthday_page = 0
        }
    }
    
})
</script>   

<style lang="scss">
    
</style>