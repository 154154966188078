import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import VendorView from '@/views/VendorView.vue'
import VendorAuth from '@/views/VendorAuth.vue'
import VendorReset from '@/views/VendorReset.vue'
import VendorRegister from '@/views/VendorRegister.vue'
import VendorProfile from '@/views/VendorProfile.vue'
import VendorAbout from '@/views/VendorAbout.vue'
import VendorFeedback from '@/views/VendorFeedback.vue'
import VendorRedirect from '@/views/VendorRedirect.vue'
import VendorSlider from '@/views/VendorSlider.vue'
import VendorProduct from '@/views/VendorProduct.vue'
import VendorCart from '@/views/VendorCart.vue'
import VendorCheckout from '@/views/VendorCheckout.vue'
import VendorLink from '@/views/VendorLink.vue'


import PageNotFound from '@/views/PageNotFound.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/:vendor',
    name: 'vendor',
    component: VendorView
  },
  {
    path: '/:vendor/s',
    component: VendorRedirect
  },
  {
    path: '/:vendor/s/:slider',
    name: 'slider',
    component: VendorSlider
  },
  {
    path: '/:vendor/p',
    component: VendorRedirect
  },
  {
    path: '/:vendor/p/:product',
    name: 'product',
    component: VendorProduct
  },
  {
    path: '/:vendor/about',
    name: 'about',
    component: VendorAbout
  },
  {
    path: '/:vendor/feedback',
    name: 'feedback',
    component: VendorFeedback
  },
  {
    path: '/:vendor/login',
    name: 'login',
    component: VendorAuth
  },
  {
    path: '/:vendor/reset',
    name: 'reset',
    component: VendorReset
  },
  {
    path: '/:vendor/register',
    name: 'register',
    component: VendorRegister
  },
  {
    path: '/:vendor/profile',
    name: 'profile',
    component: VendorProfile
  },
  {
    path: '/:vendor/cart',
    name: 'cart',
    component: VendorCart
  },
  {
    path: '/:vendor/checkout',
    name: 'checkout',
    component: VendorCheckout
  },
  {
    path: '/:vendor/link',
    name: 'link',
    component: VendorLink
  },
  { path: "/:vendor/:pathMatch(.*)*", name: 'page404', component: PageNotFound }
]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
