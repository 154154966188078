<template>
   <div :class="[$store.state.background_dark ? 'background_dark' : 'background_dark_none']"  class="h-screen w-full fixed top-0 left-0 right-0 z-20"></div>

    <div class="wrapper mx-auto flex flex-col items-center justify-center">
        <img class="w-6/12 my-5" :src="$store.state.vendor.logo" alt="">

        <button @click="$router.push({ name: 'vendor' });" type="button" class="mt-5 border border-zinc-200 w-full p-3 font-medium text-2xl rounded-lg bg-gradient-to-r from-slate-600 via-slate-500 to-slate-400 text-white">
            {{$t('word154')}}
        </button>

        <button @click="$router.push({ name: 'feedback' });" type="button" class="mt-5 border border-zinc-200 w-full p-3 font-medium text-2xl rounded-lg bg-gradient-to-r from-emerald-700 via-emerald-600 to-emerald-500 text-white">
            {{$t('word3')}}
        </button>
        
        <a v-for="c, i in $store.state.vendor.sn " :key="i" target="_blank" :href="c.address" type="button" class="text-center mt-5 border border-zinc-200 w-full p-3 font-medium text-2xl rounded-lg bg-gradient-to-r  text-white"
        :class="[c.title == 'Instagram' ? 'from-pink-600 via-pink-500 to-pink-400' : c.title == 'Whatsapp' ? 'from-green-600 via-green-500 to-green-400' : c.title == 'Telegram' ? 'from-cyan-600 via-cyan-500 to-cyan-400' : 'from-blue-600 via-blue-500 to-blue-400' ]">
            {{ c.title }}
        </a>

        <br><br><br><br>
    </div>
   
    
</template>

<script>


export default ({
    components: {},

    data() {
        return {
           
        }
    },
    mounted() {
        window.scrollTo(0, 0)
        },
    updated() {
       
    },
    methods: {
       
    }
    
})
</script>   

<style lang="scss">
    
</style>