<template>
    <div  class="rounded-t-lg bg-white fixed w-full z-30 pt-3"  :class="{'modal_view' : $store.state.category_open }">
        <div class="flex justify-center py-2"><div style="height: 3px; width:60px; background-color: #999"></div></div>
        <div class="wrapper mx-auto">
            <div class="flex justify-between items-center">
                <p class="text-lg font-medium">{{$t('word39')}}</p>
                <svg @click="$store.commit('toggleCategoryOpen')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#666" class="w-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" /></svg>
            </div>
            <div class="flex flex-wrap mt-4 items-start justify-start  content-start" style="height: 50vh; overflow: auto">
                <button @click="selectCategory(c.id)"  v-for="c, i in $store.state.categories_nav" v-bind:key="i" type="button" class="grow whitespace-nowrap text-gray-900 bg-white border border-zinc-200 m-1 p-2 font-medium rounded-lg" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': c.active}">{{ c.title }}</button>
            </div>
            <br>
        </div>
    </div>
</template>

<script>
export default ({
    data() {
        return {}
    },
    methods: {
        selectCategory(id) {
            this.$store.commit("selectCurrentCategory", id); 
            document.querySelector('#c' + id).scrollIntoView({ behavior: "smooth" });
            window.scrollTo(0, window.pageYOffset + document.querySelector('#c' + id).getBoundingClientRect().top - 110)
            this.$store.commit('toggleCategoryOpen')
        },
       
    }
    
})
</script>

<style>

</style>