import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './index.css';

import { swiffyslider } from 'swiffy-slider'
window.swiffyslider = swiffyslider;

window.addEventListener("load", () => {
    window.swiffyslider.init();
});

import "swiffy-slider/css"


import { createI18n } from 'vue-i18n/dist/vue-i18n.cjs'

import en from './assets/i18n/en.json'
import ru from './assets/i18n/ru.json'
import uz from './assets/i18n/uz.json'
import tr from './assets/i18n/tr.json'
import kz from './assets/i18n/kz.json'
import kg from './assets/i18n/kg.json'
import es from './assets/i18n/es.json'
import cn from './assets/i18n/cn.json'
import ko from './assets/i18n/ko.json'
import ar from './assets/i18n/ar.json'
import hi from './assets/i18n/hi.json'
import tj from './assets/i18n/tj.json'


const i18n = createI18n({
    locale: localStorage.getItem('currentLang') ? localStorage.getItem('currentLang') : 'ru',
    messages: {
        ru: ru,
        uz: uz,
        en: en,
        tr: tr,
        kz: kz,
        kg: kg,
        es: es,
        cn: cn,
        ko: ko,
        ar: ar,
        hi: hi,
        tj: tj
    },
})


createApp(App).use(store).use(router).use(i18n).mount('#app')
