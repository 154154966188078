<template>
   <div :class="[$store.state.background_dark ? 'background_dark' : 'background_dark_none']"  class="h-screen w-full fixed top-0 left-0 right-0 z-20"></div>

    <div class="wrapper mx-auto">
            <div style="display: grid; grid-template-columns: 3fr 2fr; grid-gap: 10px; align-items: center; margin-bottom: 5px; margin-top: 10px;">
                <div>
                    <p class="text-2xl mb-1 font-medium">{{ $store.state.vendor.title }}</p>
                    <p class="text-gray-500">{{$t('word1')}}: <span class="text-black">{{ $store.state.vendor.service }}%</span></p>
                    <p v-show="$store.state.vendor.wifi" class="text-gray-500">{{$t('word2')}}: <span class="text-black">{{ $store.state.vendor.wifi }}</span></p>
                </div>
                <img :src="$store.state.vendor.logo" alt="" class="w-full">
            </div>
            <div class="my-4 mt-5">
                <div class="flex justify-between items-center">
                    <p class="text-gray-600">{{$t('word5')}}</p>
                    <p v-if="$store.state.today.work">{{ $store.state.today.open }} - {{ $store.state.today.close }}</p>
                    <p v-else class="text-lg font-medium">{{$t('word6')}}</p>
                </div>
                <div style="display: grid; grid-template-columns: repeat(7,1fr); grid-gap: 10px; margin-top: 5px;">
                    <div @click="selectDay(1)" class="p-2 text-center rounded text-gray-900 bg-white border border-zinc-200" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400 text-white' : $store.state.today.day == 1}">{{$t('word7')}}</div>
                    <div @click="selectDay(2)" class="p-2 text-center rounded text-gray-900 bg-white border border-zinc-200" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400 text-white' : $store.state.today.day == 2}">{{$t('word8')}}</div>
                    <div @click="selectDay(3)" class="p-2 text-center rounded text-gray-900 bg-white border border-zinc-200" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400 text-white' : $store.state.today.day == 3}">{{$t('word9')}}</div>
                    <div @click="selectDay(4)" class="p-2 text-center rounded text-gray-900 bg-white border border-zinc-200" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400 text-white' : $store.state.today.day == 4}">{{$t('word10')}}</div>
                    <div @click="selectDay(5)" class="p-2 text-center rounded text-gray-900 bg-white border border-zinc-200" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400 text-white' : $store.state.today.day == 5}">{{$t('word11')}}</div>
                    <div @click="selectDay(6)" class="p-2 text-center rounded text-gray-900 bg-white border border-zinc-200" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400 text-white' : $store.state.today.day == 6}">{{$t('word12')}}</div>
                    <div @click="selectDay(7)" class="p-2 text-center rounded text-gray-900 bg-white border border-zinc-200" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400 text-white' : $store.state.today.day == 7}">{{$t('word13')}}</div>
                </div>
            </div>
            <button  @click="$router.push({ name: 'feedback' })" type="button" class="mt-3 border border-zinc-200 w-full p-3 font-medium text-xl rounded-lg bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white flex justify-center items-center">Оставить отзыв</button>
            <div class="my-4">
                <p class="text-gray-600">{{$t('word4')}}</p>
                <p class="text-lg font-medium">{{ $store.state.vendor.address }}</p>
            </div>
            <div class="my-4 "> 
                <p class="text-gray-600 mb-2">{{$t('word14')}}</p>
                <div v-for="c, i in $store.state.vendor.phones " :key="i">
                    <div class="flex justify-between items-center py-3" style="border-bottom:1px solid #cfcfcf">
                        <p>{{ c.title }}</p>
                        <a target="_blank" :href="'tel:' + c.phone">+{{ c.phone }}</a>
                    </div>
                </div>
                <div v-for="c, i in $store.state.vendor.emails " :key="i">
                    <div class="flex justify-between items-center py-3" style="border-bottom:1px solid #cfcfcf">
                        <p>{{ c.title }}</p>
                        <a target="_blank" :href="'mailto:' + c.email">{{$t('word15')}}</a>
                    </div>
                </div>
                <div v-for="c, i in $store.state.vendor.sn " :key="i">
                    <div class="flex justify-between items-center py-3" style="border-bottom:1px solid #cfcfcf">
                        <p>{{ c.title }}</p>
                        <a target="_blank" :href="c.address">{{$t('word16')}}</a>
                    </div>
                </div>
                <div v-show="$store.state.vendor.gmap">
                    <div class="flex justify-between items-center py-3" style="border-bottom:1px solid #cfcfcf">
                        <p>{{$t('word156')}}</p>
                        <p v-if="!map" @click="map = true">{{$t('word157')}}</p>
                        <p v-else @click="map = false">{{$t('word158')}}</p>
                    </div>
                    <div v-show="map">
                        <iframe  :src="$store.state.vendor.gmap" width="100%" height="250" style="border:0;z-index: -50"  loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        <div></div>
                    </div>
                </div>
            </div>

            <br><br><br><br>
    </div>
    <Navigator />
    
</template>

<script>

import  Navigator  from '@/components/Navigator.vue'

export default ({
    components: {Navigator},

    data() {
        return {
           map: false,
        }
    },
    mounted() {
        window.scrollTo(0, 0)
        },
    updated() {
       
    },
    methods: {
       selectDay(d) {
        this.$store.commit("selectDay", d); 
       }
        
    }
    
})
</script>   

<style lang="scss">
    
</style>