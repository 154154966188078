<template>
   <div :class="[$store.state.background_dark ? 'background_dark' : 'background_dark_none']"  class="h-screen w-full fixed top-0 left-0 right-0 z-20"></div>

    <div v-if="feedback_status == 1" class="wrapper mx-auto">
        <div class="flex justify-between items-center my-4">
            <p class="text-2xl">{{$t('word18')}}</p>
        </div>
        <div v-if="feedback_error == 1" class="mb-5 bg-red-600 text-white w-full text-sm rounded font-medium p-3 text-center ">
            <span>{{$t('word19')}}</span>
        </div>

        <div class="w-full relative">
            <input :disabled="$store.state.auth" type="tel" v-model="feedback_phone"
            class="form-control w-full py-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            :placeholder="$t('word22')" :class="{'bg-gray-100': $store.state.auth}" style="padding-left: 70px" />
            <span class="absolute text-xl font-normal text-gray-500" style="top: 24.6%; left: 12px">+{{ $store.state.vendor.phone_code }}</span>
        </div>
        <div class="w-full">
            <input :disabled="$store.state.auth" type="text" v-model="feedback_name"
            class=" form-control mt-5 w-full px-4 py-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            :placeholder="$t('word21')" :class="{'bg-gray-100': $store.state.auth}">
        </div>
        <div class="w-full">
            <textarea v-model="feedback_message"
            class=" form-control mt-5 w-full px-4 py-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" 
            :placeholder="$t('word23')" rows="5"></textarea>
        </div>
        <p class="text-center mt-4 mb-2">{{ $t('word24') }}</p>
        <div style="display: grid; grid-template-columns: repeat(5, 1fr); grid-gap: 5px; padding: 0 50px;">
            <svg @click="setStars(1)" xmlns="http://www.w3.org/2000/svg" :fill="[star1 ? 'gold' : 'lightgray']" viewBox="0 0 24 24" class="w-full" :stroke="[star1 ? 'gold' : 'lightgray']" ><path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" /></svg>
            <svg @click="setStars(2)" xmlns="http://www.w3.org/2000/svg" :fill="[star2 ? 'gold' : 'lightgray']" viewBox="0 0 24 24" class="w-full" :stroke="[star2 ? 'gold' : 'lightgray']" ><path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" /></svg>
            <svg @click="setStars(3)" xmlns="http://www.w3.org/2000/svg" :fill="[star3 ? 'gold' : 'lightgray']" viewBox="0 0 24 24" class="w-full" :stroke="[star3 ? 'gold' : 'lightgray']" ><path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" /></svg>
            <svg @click="setStars(4)" xmlns="http://www.w3.org/2000/svg" :fill="[star4 ? 'gold' : 'lightgray']" viewBox="0 0 24 24" class="w-full" :stroke="[star4 ? 'gold' : 'lightgray']" ><path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" /></svg>
            <svg @click="setStars(5)" xmlns="http://www.w3.org/2000/svg" :fill="[star5 ? 'gold' : 'lightgray']" viewBox="0 0 24 24" class="w-full" :stroke="[star5 ? 'gold' : 'lightgray']" ><path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" /></svg>
        </div>
        <br>
        <p style="font-size: 10px" class="text-center text-gray-700 mt-4">
                {{ $t('word138') }} <br>
                <a class="text-blue-600" target="_blank" href="https://my.yola.menu/media/agreement.pdf">{{ $t('word110') }}</a>
            </p>
        <button @click="sendFeedback()" type="button" class="mt-4 border border-zinc-200 w-full p-3 font-medium text-xl rounded-lg bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white flex justify-center items-center">{{ $t('word25') }}</button>
       
        <br><br><br>
    </div>
    <div v-else class="wrapper mx-auto" style="height: 87vh; display: flex;">
        <div class="flex justify-center text-center items-center flex-col">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="rgb(37, 99 ,235, 0.9)" width="180px"><path stroke-linecap="round" stroke-linejoin="round" d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" /></svg>
            <p class="text-2xl my-5">
                {{ $t('word26') }}
            </p>
            <p class="text-gray-600 text-sm">
                {{ $t('word27') }}
            </p>
            <button @click="$router.push({ name: 'vendor' }); delScroll()" type="button" class="mt-4 border border-zinc-200 w-full p-3 font-medium text-xl rounded-lg bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white flex justify-center items-center">{{ $t('word28') }}</button>
        </div>
    </div>
    <Navigator />
    
</template>

<script>

import  Navigator  from '@/components/Navigator.vue'

export default ({
    components: {Navigator},

    data() {
        return {
            background_dark: false,
            feedback_status: 1,
            stars: 0,
            star1: false,
            star2: false,
            star3: false,
            star4: false,
            star5: false,

            feedback_error: 0,
            feedback_name: "",
            feedback_phone: "",
            feedback_message: ""

        }
    },
    mounted() {
        if (this.$store.state.auth) {
            this.feedback_name = this.$store.state.user_name
            if (this.$store.state.vendor.phone_code == '998') {
                this.feedback_phone = this.$store.state.user_phone.slice(3)
            } else {
                this.feedback_phone = this.$store.state.user_phone.slice(3)
            }
        }
        window.scrollTo(0, 0)
        },
        
    updated() {
       if (this.$store.state.auth) {
            this.feedback_name = this.$store.state.user_name
            if (this.$store.state.vendor.phone_code == '998') {
                this.feedback_phone = this.$store.state.user_phone.slice(3)
            } else {
                this.feedback_phone = this.$store.state.user_phone.slice(3)
            }
            
       }
    },
    methods: {
      setStars(s) {
        switch (s) {
            case 1:
                this.star1 = true
                this.star2 = false
                this.star3 = false
                this.star4 = false
                this.star5 = false
                this.stars = 1
                break
            case 2:
                this.star1 = true
                this.star2 = true
                this.star3 = false
                this.star4 = false
                this.star5 = false
                this.stars = 2
                break
            case 3:
                this.star1 = true
                this.star2 = true
                this.star3 = true
                this.star4 = false
                this.star5 = false
                this.stars = 3
                break
            case 4:
                this.star1 = true
                this.star2 = true
                this.star3 = true
                this.star4 = true
                this.star5 = false
                this.stars = 4
                break
            case 5:
                this.star1 = true
                this.star2 = true
                this.star3 = true
                this.star4 = true
                this.star5 = true
                this.stars = 5
                break
        }
      },
      sendFeedback() {
        this.feedback_error = 0
        if (this.stars && this.feedback_name.length && this.feedback_phone.length == this.$store.state.vendor.phone_length && this.feedback_message.length) {
            fetch(`${this.$store.state.url}/send/message/`, {
                method: "POST",
                headers: { "Content-Type": "application/json", Accept: "application/json", },
                body: JSON.stringify(
                    {
                    "auth": this.$store.state.auth,
                    "token": this.$store.state.auth_token,
                    "phone": this.feedback_phone,
                    "name": this.feedback_name,
                    "message": this.feedback_message,
                    "rating": this.stars,
                    "code": this.$store.state.vendor_code,
                }),
            })
            this.feedback_error = 0
            this.feedback_status = 2
        } else {
            this.feedback_error = 1
        }
      },
      delScroll() {
            localStorage.removeItem('scroll')
        }
    }
    
})
</script>   

<style lang="scss">
    
</style>