<template>
    <div>
         <!-- Navigator -->
    <div id="navigator">
        <div class="nav-block">
            <div class="container">
                <div class="nav-wrapper">
                    <div class="logo">
                        <img src="@/assets/logo.png" alt="">
                        <img src="@/assets/icons/menu-burger.svg" class="menu-burger" alt="">
                    </div>
                    <div class="nav-link" id="nav_menu">
                        <div class="nav-menu">
                            <img class="menu-logo" src="@/assets/logo.png" alt="">
                            <img class="close-menu" src="@/assets/icons/close.svg" alt="">
                        </div>
                        <ul class="nav-link-block">
                            <li class="nav-link-item"><a href="">Преимущества</a></li>
                            <li class="nav-link-item"><a href="">Партнеры</a></li>
                            <li class="nav-link-item"><a href="">Тарифы</a></li>
                            <li class="nav-link-item"><a href="">F.A.Q</a></li>
                            <li class="nav-link-item"><a href="">Войти</a></li>
                            <li class="nav-link-item"><a href="https://my.yola.menu/register/" class="register-btn">Регистрация</a></li>
                        </ul>
                        <div class="nav-menu-footer">
                            <div class="row">
                                <div class="col-sm-6"><a href="#.">info@yolagroup.com</a></div>
                                <div class="col-sm-6"><a href="#.">+998 93 009 95 95</a></div>
                            </div>
                            <a href="#.">Узбекистан, г. Ташкент ул. Богибустон 186-188</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Home -->
    <div id="home">
        <div class="home-block">
            <div class="container">
                <div class="home-wrapper">
                    <div class="about">
                        <h1 class="title">Yola Menu </h1>
                        <p class="about-text">это онлайн-меню, разработанное для улучшения обслуживания в ресторанном бизнесе. Это новое поколение онлайн-заказов, которое позволит вам заявить о себе с лучшей стороны и дать клиентам больше уверенности в вашем бренде.</p>
                        <a href="#." class="btn btn-outline-primary rounded-pill">Также работаем с гостиницами</a><br>
                        <div class="register-btn lg-reg-btn text-center d-inline-block mt-5">
                            <a href="https://my.yola.menu/register/" class="btn btn-primary rounded-pill btn-lg reg-btn">Регистрация</a>
                            <p class="text-muted my-2">7 дней бесплатно</p>
                        </div>
                    </div>
                    <div class="menu">
                        <img src="@/assets/images/menu.png" alt="">
                    </div>
                    <div class="register-btn md-reg-btn text-center d-inline-block mt-5">
                        <a href="https://my.yola.menu/register/" class="btn btn-primary rounded-pill btn-lg reg-btn">Регистрация</a>
                        <p class="text-muted my-2">7 дней бесплатно</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Our Services -->
    <div id="services">
        <div class="services-block">
            <div class="container">
                <h1 class="title text-center">Наши сервисы</h1>
                <div class="service-content">
                    <div class="service-item">
                        <h4 class="s-title">Вызов официанта</h4>
                        <div class="s-icon">
                            <div class="icon"><img src="@/assets/icons/menu.svg" alt=""></div>
                        </div>
                    </div>
                    <div class="service-item">
                        <h4 class="s-title">Метрика</h4>
                        <div class="s-icon">
                            <div class="icon"><img src="@/assets/icons/metric.svg" alt=""></div>
                        </div>
                    </div>
                    <div class="service-item">
                        <h4 class="s-title">Функция доставки</h4>
                        <div class="s-icon">
                            <div class="icon"><img src="@/assets/icons/delivery.svg" alt=""></div>
                        </div>
                    </div>
                    <div class="service-item">
                        <h4 class="s-title">Личный кабинет</h4>
                        <div class="s-icon">
                            <div class="icon"><img src="@/assets/icons/user.svg" alt=""></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div>
        <div v-if="form_status" class="application container text-center">
            <h1 style="font-size: 50px" class="title">Ваша заявка принята ✅</h1>
            <h1 class="content my-3">Спасибо! Мы перезвоним вам в ближайшее время</h1>
        </div>
        <div v-else class="consultation container">
            <h1 class="title">Получите бесплатную консультацию</h1>
            <div class="form">

                    <div v-if="form_error" class="p-2  bg-red-600 text-white rounded mb-3">Заполните все поля</div>
                    <input type="text" placeholder="ФИО"  v-model="form_name">
                    <input type="tel" placeholder="Номер телефона" v-model="form_phone">
                    <button @click="formSend()" type="button" class="btn btn-primary rounded-pill btn-lg reg-btn">Заказать звонок</button><br>
                    <smal class="text-muted">Нажимая на кнопку "Заказать звонок" <a class="text-blue-600" href="https://my.yola.menu/media/oferta.pdf">вы соглашаетесь с пользовательским соглашением и обработкой персональных данных</a></smal>
             
            </div>
        </div>
    </div>

    <!-- Benfits -->
    <div id="benfits">
        <div class="benfit-block">
            <div class="container">
                <h1 class="title text-center">Все преимущества Yola menu</h1>
                <div class="benfit-content">
                    <div class="benfit-item">
                        <h3 class="b-title">Удобное меню в смартфоне</h3>
                        <div class="icon"><img src="@/assets/icons/menu.svg" alt=""></div>
                    </div>
                    <div class="benfit-item">
                        <h3 class="b-title">Показ акций и выгодных предложений</h3>
                        <div class="icon"><img src="@/assets/icons/menu.svg" alt=""></div>
                    </div>
                    <div class="benfit-item">
                        <h3 class="b-title">Удобное меню в смартфоне</h3>
                        <div class="icon"><img src="@/assets/icons/menu.svg" alt=""></div>
                    </div>
                    <div class="benfit-item">
                        <h3 class="b-title">Статистика посещений</h3>
                        <div class="icon"><img src="@/assets/icons/menu.svg" alt=""></div>
                    </div>
                    <div class="benfit-item">
                        <h3 class="b-title">Уведомления о доставке блюд</h3>
                        <div class="icon"><img src="@/assets/icons/menu.svg" alt=""></div>
                    </div>
                    <div class="benfit-item">
                        <h3 class="b-title">Обратная связь</h3>
                        <div class="icon"><img src="@/assets/icons/menu.svg" alt=""></div>
                    </div>
                    <div class="benfit-item">
                        <h3 class="b-title">Возможность вносить изменения без ограничений</h3>
                        <div class="icon"><img src="@/assets/icons/menu.svg" alt=""></div>
                    </div>
                    <div class="benfit-item">
                        <h3 class="b-title">Экономия финансов на печать</h3>
                        <div class="icon"><img src="@/assets/icons/menu.svg" alt=""></div>
                    </div>
                    <div class="benfit-item">
                        <h3 class="b-title">Гости узнают о наличии блюд в два клика</h3>
                        <div class="icon"><img src="@/assets/icons/menu.svg" alt=""></div>
                    </div>
                    <div class="benfit-item">
                        <h3 class="b-title">Автоматический перевод меню на 12 языков для каждого вендора</h3>
                        <div class="icon"><img src="@/assets/icons/menu.svg" alt=""></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Admin Block -->
    <div class="admin-block container">
        <div class="about">
            <h1 class="title">Удобная <br> админ панель</h1>
            <h4 class="text-muted content">Мы знаем, как много работы уходит на создание меню и его поддержание. Поэтому мы предлагаем панель управления, где вы можете управлять своим онлайн-меню всего двумя щелчками мыши!</h4>
        </div>
        <div class="admin-img">
            <img src="@/assets/images/admin.png" class="img-fluid" alt="">
        </div>
    </div>

    <!-- Tariffs -->
    <div id="tariffs">
        <div class="tariff-block">
            <div class="container">
                <h1 class="title text-center">Тарифы</h1>
                <h2 class="content-text text-center">Первые 7 дней <span class="text-danger">БЕСПЛАТНО</span></h2>
                <div class="tariff-content">
                    <div class="tariff-item">
                        <h2 class="t-title">Ежемесячная</h2>
                        <h1 class="price">200 000 сум</h1>
                        <p class="content"></p>
                        <a href="#." class="btn btn-danger rounded-pill">Подключить</a>
                    </div>
                    <div class="tariff-item">
                        <h2 class="t-title">Годовая</h2>
                        <h1 class="price">1 800 000 сум</h1>
                        <p class="content text-muted">(экономия 600 000 сум)</p>
                        <a href="#." class="btn btn-danger rounded-pill">Подключить</a>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div>
        <div v-if="form_status" class="application container text-center">
            <h1 style="font-size: 50px" class="title">Ваша заявка принята ✅</h1>
            <h1 class="content my-3">Спасибо! Мы перезвоним вам в ближайшее время</h1>
        </div>
        <div v-else class="consultation container">
            <h1 class="title">Получите бесплатную консультацию</h1>
            <div class="form">
                    <div v-if="form_error" class="p-2  bg-red-600 text-white rounded mb-3">Заполните все поля</div>
                    <input type="text" placeholder="ФИО"  v-model="form_name">
                    <input type="tel" placeholder="Номер телефона" v-model="form_phone">
                    <button @click="formSend()" type="button" class="btn btn-primary rounded-pill btn-lg reg-btn">Заказать звонок</button><br>
                    <smal class="text-muted">Нажимая на кнопку "Заказать звонок" <a class="text-blue-600" href="https://my.yola.menu/media/oferta.pdf">вы соглашаетесь с пользовательским соглашением и обработкой персональных данных</a></smal>
            </div>
        </div>
    </div>


    <!-- Footer -->
    <div id="footer">
        <div class="footer-block">
            <div class="container">
                <div class="footer-wrapper">
                    <div class="f-content">
                        <div class="logo my-1"><img src="@/assets/logo2.png" alt=""></div>
                        <div class="logo my-1"><img width="58%" src="@/assets/it_park.png" alt=""></div>
                    </div>
                    <div class="f-content">
                        <a href="#.">Преимущества</a>
                        <a href="#.">Партнеры</a>
                        <a href="#.">Тарифы</a>
                    </div>                 
                    <div class="f-content">
                        <a href="#.">Часто задаваемые вопросы</a>
                        <a href="#.">Войти</a>
                        <a href="#.">Регистрация</a>
                    </div>
                    <div class="f-content">
                        <a href="mailto:info@yolagroup.com">info@yolagroup.com</a>
                        <a href="tel:+998 93 009 95 95">+998 93 009 95 95</a>
                        <a href="#.">Узбекистан, г. Ташкент ул. Богибустон 186-188</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="my_modal" class="my-modal"></div>
    </div>
</template>

<script>
if(document.querySelector('.menu-burger')){
    document.querySelector('.menu-burger').addEventListener('click', () => {
        document.querySelector('#nav_menu').classList.add('open')
        document.querySelector('#my_modal').classList.add('show')
    })
    document.querySelector('.close-menu').addEventListener('click', () => {
        document.querySelector('#nav_menu').classList.remove('open')
        document.querySelector('#my_modal').classList.remove('show')
    })
}

if(document.getElementsByClassName("acc-btn")){
    var acc = document.getElementsByClassName("acc-btn");
    var i;
    
    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function() {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        } 
      });
    }
}

export default ({
    components: {Navigator},

    data() {
        return {
            form_error: false,
            form_status: false,
            form_name: "",
            form_phone: ""
        }
    },
    mounted() {
        
        this.$store.state.loading = false
       this.$store.state.expired = false
       this.$store.state.not_found = false
    },
    updated() {
       this.$store.state.loading = false
       this.$store.state.expired = false
       this.$store.state.not_found = false
    },
    methods: {
        formSend() {
            if (this.form_name.length) {
                if (this.form_phone.length) {
                    this.form_status = true
                } else {
this.form_error = true
                }
            } else {
this.form_error = true
            }
        }
    }
})
</script>

<style scoped>
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #fff;
  color: #333;
  line-height: 1.5;
}

.container {
  max-width: 90% !important;
  margin: 0 auto;
}

@media (min-width: 1600px) {
  .container {
    max-width: 1400px !important;
  }
}

@media (max-width: 576px) {
  .container {
    max-width: 92% !important;
  }
}

a:focus,
input:focus,
textarea:focus,
button:focus,
select:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  text-decoration: none;
}

a {
  text-decoration: none;
}

ul li {
  list-style-type: none;
}

i,
span,
a {
  display: inline-block;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

a, button, img, input {
  outline: 0;
}

p {
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.d-inline-block {
  display: inline-block !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mb-5 {
  margin-top: 3rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.text-muted {
  color: #6c757d !important;
}

.text-prime {
  color: #5B89C2 !important;
}

.btn-outline-primary {
  color: #5B89C2 !important;
  border-color: #5B89C2 !important;
  font-weight: 500 !important;
}

.btn-outline-primary:hover {
  color: #fff !important;
  background-color: #5B89C2 !important;
}

.btn-primary {
  color: #fff !important;
  border-color: #5B89C2;
  font-weight: 500;
  background-color: #5B89C2 !important;
}

.btn-primary:hover {
  border-color: #5B89C2;
  color: #fff !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}

@media (max-width: 997px) {
  .my-modal.show {
    opacity: 0.5 !important;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    background-color: #000;
    -webkit-transition: 0.7s;
    transition: 0.7s;
  }
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #0d6efd;
  text-decoration: none;
  -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

#navigator {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 101;
}

#navigator .nav-block {
  padding: 1rem 0rem;
  -webkit-box-shadow: 0px 4px 40px rgba(244, 246, 249, 0.5);
          box-shadow: 0px 4px 40px rgba(244, 246, 249, 0.5);
}

#navigator .nav-block .nav-wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 3fr 8fr;
      grid-template-columns: 3fr 8fr;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#navigator .nav-block .nav-wrapper .menu-burger {
  display: none;
}

@media (max-width: 997px) {
  #navigator .nav-block .nav-wrapper {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
  #navigator .nav-block .nav-wrapper .menu-burger {
    display: block;
  }
}

#navigator .nav-block .nav-wrapper .nav-link {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

#navigator .nav-block .nav-wrapper .nav-link.open {
  right: 0 !important;
}

#navigator .nav-block .nav-wrapper .nav-link .nav-link-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 0rem;
}

#navigator .nav-block .nav-wrapper .nav-link .nav-link-block .nav-link-item a {
  color: #333;
  font-weight: 600;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  font-size: 17px;
}

#navigator .nav-block .nav-wrapper .nav-link .nav-link-block .nav-link-item a:hover {
  color: #5B89C2;
}

@media (max-width: 1200px) {
  #navigator .nav-block .nav-wrapper .nav-link .nav-link-block .nav-link-item a {
    font-size: 15px;
  }
}

#navigator .nav-block .nav-wrapper .nav-link .nav-link-block .nav-link-item.active a {
  color: #5B89C2;
}

#navigator .nav-block .nav-wrapper .nav-link .nav-link-block .nav-link-item .register-btn {
  background-color: #EEF6FF;
  padding: 0.5rem 1rem;
  border-radius: 30px;
  color: #5B89C2;
}

#navigator .nav-block .nav-wrapper .nav-link .nav-menu {
  display: none;
}

#navigator .nav-block .nav-wrapper .nav-link .nav-menu-footer {
  display: none;
}

@media (max-width: 997px) {
  #navigator .nav-block .nav-wrapper .nav-link {
    position: fixed;
    z-index: 101;
    right: -100%;
    top: 0;
    width: 50vh;
    height: 100vh;
    background-color: #fff;
    border-radius: 10px 0px 0px 10px;
  }
  #navigator .nav-block .nav-wrapper .nav-link .nav-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 1rem 0rem 2rem 0rem;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-bottom: 1px solid #5B89C2;
  }
  #navigator .nav-block .nav-wrapper .nav-link .nav-menu .menu-logo {
    width: 140px;
  }
  #navigator .nav-block .nav-wrapper .nav-link .nav-link-block {
    display: block;
    margin-bottom: 0rem;
    margin: 0rem !important;
    padding: 1rem;
  }
  #navigator .nav-block .nav-wrapper .nav-link .nav-link-block .nav-link-item {
    margin: 1.5rem 0rem;
  }
  #navigator .nav-block .nav-wrapper .nav-link .nav-link-block .nav-link-item a {
    color: #333;
    font-weight: 600;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    font-size: 20px;
  }
  #navigator .nav-block .nav-wrapper .nav-link .nav-link-block .nav-link-item a:hover {
    color: #5B89C2;
  }
  #navigator .nav-block .nav-wrapper .nav-link .nav-link-block .nav-link-item.active a {
    color: #5B89C2;
  }
  #navigator .nav-block .nav-wrapper .nav-link .nav-link-block .nav-link-item .register-btn {
    background-color: #EEF6FF;
    padding: 0.5rem 1rem;
    border-radius: 30px;
    color: #5B89C2;
    width: 100%;
    text-align: center;
  }
  #navigator .nav-block .nav-wrapper .nav-link .nav-menu-footer {
    display: -ms-grid;
    display: grid;
    border-top: 1px solid #5B89C2;
    padding: 1rem 0rem;
    margin: 0.5rem;
  }
  #navigator .nav-block .nav-wrapper .nav-link .nav-menu-footer a {
    color: #333;
    font-size: 18px;
    font-weight: 600;
    margin: 0.5rem 0rem;
  }
}

@media (max-width: 576px) {
  #navigator .nav-block .nav-wrapper .nav-link {
    width: 100% !important;
    height: 100%;
  }
  #navigator .nav-block .nav-wrapper .nav-link .nav-link-block {
    display: block;
    margin-bottom: 0rem;
    margin: 0rem !important;
    padding: 1rem;
  }
  #navigator .nav-block .nav-wrapper .nav-link .nav-link-block .nav-link-item {
    margin: 1rem 0rem;
  }
  #navigator .nav-block .nav-wrapper .nav-link .nav-link-block .nav-link-item a {
    font-size: 18px;
  }
  #navigator .nav-block .nav-wrapper .nav-link .nav-link-block .nav-link-item .register-btn {
    background-color: #EEF6FF;
    padding: 0.5rem 1rem;
    border-radius: 30px;
    color: #5B89C2;
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 997px) {
  #navigator .nav-block .nav-wrapper .logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  #navigator .nav-block .nav-wrapper .logo img {
    width: 150px;
  }
  #navigator .nav-block .nav-wrapper .logo .menu-burger {
    width: 35px !important;
    height: 35px !important;
  }
}

.home-block {
  margin: 2rem 0rem;
  padding: 2rem 0rem;
}

@media (max-width: 576px) {
  .home-block {
    margin: 1rem 0rem;
  }
}

.home-block .home-wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 5fr 3fr;
      grid-template-columns: 5fr 3fr;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  grid-gap: 2rem;
}

.home-block .home-wrapper .md-reg-btn {
  display: none !important;
}

@media (max-width: 767px) {
  .home-block .home-wrapper {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
  .home-block .home-wrapper .lg-reg-btn {
    display: none !important;
  }
  .home-block .home-wrapper .md-reg-btn {
    display: block !important;
  }
  .home-block .home-wrapper .md-reg-btn .reg-btn {
    width: 100% !important;
  }
}

.home-block .home-wrapper .about .title {
  font-weight: 600;
  font-size: 50px;
  margin-bottom: 1.5rem;
}

.home-block .home-wrapper .about .about-text {
  font-size: 27px;
}

.home-block .home-wrapper .about .reg-btn {
  padding: 0.7rem 2rem;
}

@media (max-width: 1200px) {
  .home-block .home-wrapper .about .title {
    font-size: 40px;
    margin-bottom: 1rem;
  }
  .home-block .home-wrapper .about .about-text {
    font-size: 22px;
  }
  .home-block .home-wrapper .about .reg-btn {
    padding: 0.5rem 2rem;
  }
}

@media (max-width: 997px) {
  .home-block .home-wrapper .about .title {
    font-size: 40px;
    margin-bottom: 1rem;
  }
  .home-block .home-wrapper .about .about-text {
    font-size: 18px;
  }
  .home-block .home-wrapper .about .reg-btn {
    padding: 0.5rem 2rem;
  }
}

.home-block .home-wrapper .menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.home-block .home-wrapper .menu img {
  width: 350px;
}

@media (max-width: 1200px) {
  .home-block .home-wrapper .menu img {
    width: 300px;
  }
}

@media (max-width: 997px) {
  .home-block .home-wrapper .menu img {
    width: 250px;
  }
}

.services-block {
  padding: 2rem 0rem;
}

@media (max-width: 576px) {
  .services-block {
    margin: 1rem 0rem;
  }
  .services-block .title {
    font-size: 2rem;
  }
}

.services-block .title {
  font-weight: 600;
  font-size: 2.5rem;
}

.services-block .service-content {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1.5rem;
  margin: 4rem 0rem;
}

@media (max-width: 767px) {
  .services-block .service-content {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    margin: 2rem 0rem;
  }
}

.services-block .service-content .service-item {
  border: 1px solid #C5C5C5;
  border-radius: 20px;
  padding: 2rem 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.services-block .service-content .service-item .s-title {
  padding-bottom: 2.5rem;
  font-size: 28px;
  font-weight: 500;
}

@media (min-width: 1400px) {
  .services-block .service-content .service-item .s-title {
    padding-bottom: 4rem;
    font-size: 32px;
  }
}

@media (max-width: 997px) {
  .services-block .service-content .service-item .s-title {
    padding-bottom: 2rem;
    font-size: 22px;
  }
}

@media (max-width: 997px) {
  .services-block .service-content .service-item {
    padding: 1rem;
  }
  .services-block .service-content .service-item .s-icon .icon {
    padding: 0.4rem !important;
    border-radius: 50%;
  }
  .services-block .service-content .service-item .s-icon .icon img {
    width: 25px !important;
    height: 25px !important;
  }
}

.services-block .service-content .service-item .s-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.services-block .service-content .service-item .s-icon .icon {
  background-color: #EFEFEF;
  padding: 0.8rem;
  border-radius: 50%;
}

.services-block .service-content .service-item .s-icon .icon img {
  width: 35px;
  height: 35px;
}

.consultation {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 5fr 4fr;
      grid-template-columns: 5fr 4fr;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #F4F9FF;
  border-radius: 20px;
  padding: 2rem;
  margin-bottom: 4rem;
  grid-gap: 30px 10px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media (max-width: 767px) {
  .consultation {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    margin-top: 4rem;
    padding: 1.5rem;
  }
}

.consultation .title {
  font-size: 50px;
}

@media (max-width: 1200px) {
  .consultation .title {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .consultation .title {
    font-size: 25px;
  }
}

.consultation .form {
  max-width: 100%;
}

.consultation .form input {
  display: block;
  width: 100%;
  border-radius: 30px;
  border: 2px solid #acacad;
  margin-bottom: 1rem;
  font-size: 20px;
  color: #333;
  padding: 1rem 1rem;
  background-color: #F4F9FF;
}

.consultation .form input:focus {
  border: 2px solid #5B89C2;
}

.consultation .form .reg-btn {
  padding: 0.7rem 2rem;
  width: 100%;
  margin-bottom: 0.5rem;
}

@media (max-width: 576px) {
  .consultation .form input {
    display: block;
    width: 100%;
    border-radius: 30px;
    border: 2px solid #acacad;
    margin-bottom: 1rem;
    font-size: 16px;
    color: #333;
    padding: 0.7rem 1rem;
    background-color: #F4F9FF;
  }
  .consultation .form .reg-btn {
    padding: 0.5rem 2rem;
    font-size: 16px;
  }
}

.benfit-block {
  padding: 2rem 0rem;
  margin: 2rem 0rem;
}

@media (max-width: 576px) {
  .benfit-block {
    margin: 1rem 0rem;
  }
  .benfit-block .title {
    font-size: 1.7rem;
  }
}

.benfit-block .title {
  font-weight: 600;
  font-size: 2.5rem;
}

@media (max-width: 767px) {
  .benfit-block .title {
    font-size: 2rem;
  }
}

@media (max-width: 576px) {
  .benfit-block .title {
    font-size: 1.7rem;
  }
}

.benfit-block .benfit-content {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  margin: 3rem 0rem;
}

@media (max-width: 576px) {
  .benfit-block .benfit-content {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }
}

@media (max-width: 1200px) {
  .benfit-block .benfit-content {
    grid-gap: 1.5rem;
  }
}

@media (max-width: 767px) {
  .benfit-block .benfit-content {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    margin: 2rem 0rem;
  }
}

.benfit-block .benfit-content .benfit-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #F8F8F8;
  border-radius: 15px;
  padding: 2rem 1rem;
}

.benfit-block .benfit-content .benfit-item .b-title {
  margin-right: 10px;
  font-size: 28px;
  font-weight: 500;
  line-height: 1.2;
}

@media (max-width: 1200px) {
  .benfit-block .benfit-content .benfit-item .b-title {
    font-size: 22px;
    margin-right: 10px;
  }
}

@media (max-width: 997px) {
  .benfit-block .benfit-content .benfit-item .b-title {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .benfit-block .benfit-content .benfit-item .b-title {
    font-size: 24px;
  }
}

@media (max-width: 576px) {
  .benfit-block .benfit-content .benfit-item .b-title {
    font-size: 20px;
  }
}

.benfit-block .benfit-content .benfit-item .icon {
  background-color: #EFEFEF;
  padding: 0.8rem;
  border-radius: 50%;
}

.benfit-block .benfit-content .benfit-item .icon img {
  width: 35px;
  height: 35px;
}

@media (max-width: 1200px) {
  .benfit-block .benfit-content .benfit-item .icon img {
    width: 25px;
    height: 25px;
  }
}

.admin-block {
  background-color: #FDEEEE;
  border-radius: 15px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  grid-gap: 2rem;
  padding: 2rem;
}

.admin-block .title {
  font-size: 3rem;
  margin: 1rem 0rem;
  line-height: 1.2;
}

.admin-block .content {
  font-weight: 500;
  font-size: 20px;
}

@media (max-width: 767px) {
  .admin-block {
    padding: 1.5rem !important;
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
}

@media (max-width: 576px) {
  .admin-block {
    padding: 1rem !important;
  }
}

@media (max-width: 997px) {
  .admin-block .title {
    font-size: 2rem;
  }
  .admin-block .content {
    font-size: 16px;
  }
}

.admin-block .about {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.admin-block .admin-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.client-block {
  padding: 2rem 0rem;
  margin: 2rem 0rem;
}

@media (max-width: 576px) {
  .client-block {
    margin: 1rem 0rem;
  }
}

.client-block .title {
  font-weight: 600;
  font-size: 2.5rem;
}

@media (max-width: 767px) {
  .client-block .title {
    font-size: 2rem;
  }
}

@media (max-width: 576px) {
  .client-block .title {
    font-size: 1.7rem;
  }
}

.client-block .client-content {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 2rem;
  margin: 3rem 0rem;
  background-color: #F8F8F8;
  padding: 2rem;
  border-radius: 20px;
}

@media (max-width: 1200px) {
  .client-block .client-content {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

@media (max-width: 997px) {
  .client-block .client-content {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (max-width: 767px) {
  .client-block .client-content {
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 576px) {
  .client-block .client-content {
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
    padding: 1rem;
  }
  .client-block .client-content .item img {
    height: 100px !important;
  }
}

.client-block .client-content .item img {
  border-radius: 20px;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  height: 150px;
}

.tariff-block {
  padding-top: 2rem;
  margin-top: 2rem;
}

@media (max-width: 576px) {
  .tariff-block {
    margin: 1rem 0rem;
  }
}

.tariff-block .title {
  font-weight: 600;
  font-size: 2.5rem;
}

@media (max-width: 767px) {
  .tariff-block .title {
    font-size: 2rem;
  }
}

@media (max-width: 576px) {
  .tariff-block .title {
    font-size: 1.7rem;
  }
}

.tariff-block .content-text {
  font-weight: 500;
}

.tariff-block .content-text .text-danger {
  color: #DC3545;
}

.tariff-block .tariff-content {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  grid-gap: 2.5rem;
  margin: 4rem 0rem;
  border-radius: 20px;
}

@media (max-width: 767px) {
  .tariff-block .tariff-content {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
}

.tariff-block .tariff-content .tariff-item {
  background-color: #FDEEEE;
  -webkit-box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
          box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.tariff-block .tariff-content .tariff-item .btn-danger {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
  padding: 0.5rem;
  font-size: 20px;
  margin-top: 1rem;
}

@media (max-width: 576px) {
  .tariff-block .tariff-content .tariff-item {
    padding: 1.5rem;
  }
}

.tariff-block .tariff-content .tariff-item .price {
  font-weight: 600;
  font-size: 2.5rem;
}

@media (max-width: 767px) {
  .tariff-block .tariff-content .tariff-item .price {
    font-size: 2rem;
  }
}

@media (max-width: 576px) {
  .tariff-block .tariff-content .tariff-item .price {
    font-size: 1.7rem;
  }
}

.tariff-block .tariff-content .tariff-item .t-title {
  font-weight: 500;
}

.application {
  background-color: #F4F9FF;
  padding: 2rem;
  border-radius: 15px;
  margin-top: 4rem;
  padding-bottom: 2rem;
  margin-bottom: 4rem;
}

@media (max-width: 576px) {
  .application {
    padding: 1.5rem;
  }
}

.application .content {
  font-weight: 500;
}

.faq-block {
  padding: 2rem 0rem;
  margin: 2rem 0rem;
}

@media (max-width: 576px) {
  .faq-block {
    margin: 1rem 0rem;
  }
}

.faq-block .prime-title {
  font-weight: 600;
  font-size: 2.5rem;
}

@media (max-width: 767px) {
  .faq-block .prime-title {
    font-size: 2rem;
  }
}

@media (max-width: 576px) {
  .faq-block .prime-title {
    font-size: 1.7rem;
  }
}

.faq-block .accordion-content {
  margin: 3rem 0rem;
}

.faq-block .accordion-content .accordion-item {
  background-color: #F8F8F8;
  border: none;
  border-radius: 20px;
  padding: 2rem;
  margin: 2rem 0rem;
}

@media (max-width: 767px) {
  .faq-block .accordion-content .accordion-item {
    padding: 1rem;
  }
  .faq-block .accordion-content .accordion-item .acc-btn {
    margin: 1rem 1rem;
  }
  .faq-block .accordion-content .accordion-item .acc-btn .title {
    font-size: 20px;
    text-align: left;
  }
  .faq-block .accordion-content .accordion-item .acc-btn .icon {
    border-radius: 50%;
    border: 2px solid #333333 !important;
    padding: 5px !important;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    margin: 0rem 1rem;
  }
  .faq-block .accordion-content .accordion-item .acc-btn .icon svg {
    height: 15px;
    width: 25px;
  }
  .faq-block .accordion-content .accordion-item .content {
    background-color: #fff;
    margin: 0rem 0.5rem !important;
    padding: 0rem 0.5rem !important;
    border-radius: 15px;
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height 0.2s ease-out;
    transition: max-height 0.2s ease-out;
  }
  .faq-block .accordion-content .accordion-item .content .text {
    font-size: 16px !important;
    color: #000000;
    padding: 0.5rem !important;
  }
}

.faq-block .accordion-content .accordion-item .acc-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: none;
  background-color: transparent;
  margin: 1rem 1rem;
}

.faq-block .accordion-content .accordion-item .acc-btn .icon {
  border-radius: 50%;
  border: 3px solid #333333;
  padding: 10px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.faq-block .accordion-content .accordion-item .acc-btn .title {
  font-size: 1.8rem;
}

@media (max-width: 767px) {
  .faq-block .accordion-content .accordion-item .acc-btn .title {
    font-size: 1.6rem;
  }
}

@media (max-width: 576px) {
  .faq-block .accordion-content .accordion-item .acc-btn .title {
    font-size: 1.2rem;
  }
}

.faq-block .accordion-content .accordion-item .acc-btn.active .icon {
  background-color: #333;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.faq-block .accordion-content .accordion-item .acc-btn.active .icon svg {
  fill: #fff;
}

.faq-block .accordion-content .accordion-item .content {
  background-color: #fff;
  margin: 0rem 1rem;
  padding: 0rem  1.5rem;
  border-radius: 15px;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.2s ease-out;
  transition: max-height 0.2s ease-out;
}

.faq-block .accordion-content .accordion-item .content .text {
  font-size: 24px;
  color: #000000;
  padding: 1rem;
  font-weight: 500;
}

.footer-block {
  background-color: #333333;
  padding: 2rem 0rem;
}

.footer-block .footer-wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px 10px;
}

@media (max-width: 997px) {
  .footer-block .footer-wrapper {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    grid-gap: 30px 10px;
  }
}

@media (max-width: 576px) {
  .footer-block .footer-wrapper {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    grid-gap: 30px 10px;
  }
}

.footer-block .footer-wrapper .f-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.footer-block .footer-wrapper .f-content a {
  display: block;
  margin: 0.4rem 0rem;
  color: #fff;
  font-size: 18px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

@media (max-width: 576px) {
  .footer-block .footer-wrapper .f-content a {
    margin: 0.2rem;
  }
}

.footer-block .footer-wrapper .f-content a:hover {
  color: #5B89C2;
}
/*# sourceMappingURL=style.css.map */
</style>