<template>
   
    <div v-if="$store.state.not_found" id="not_found" >
        <div class="wrapper mx-auto flex flex-col justify-center items-center">
            <h1 style="font-size: 40px; margin-bottom: 10px; color: rgb(215, 80, 80);">404</h1>
            <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" width="150" height="150"><path stroke-linecap="round" stroke-linejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" /></svg>
            <br>
            <p>{{$t('word139')}}</p>
            <button @click="$router.push({ name: 'vendor' }); $store.state.not_found = false" type="button" class="mt-4 border border-zinc-200 w-full p-3 font-medium text-xl rounded-lg bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white flex justify-center items-center">{{$t('word150')}}</button>
        </div>
    </div>

    <div :class="[$store.state.background_dark ? 'background_dark' : 'background_dark_none']"  class="h-screen w-full fixed top-0 left-0 right-0 z-20"></div>
    <div :class="[background_dark ? 'background_dark' : 'background_dark_none']"  class="h-screen w-full fixed top-0 left-0 right-0 z-20"></div>
    
    
    <div v-if="$store.state.slider" class="wrapper mx-auto">
        <div class="relative">
            <div @click="$router.push({ name: 'vendor' });" class="text-gray-700 flex flex-col items-center absolute bg-white py-1 px-3 rounded shadow-lg" style="top: 3px; left: 3px">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3"><path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" /></svg>
                <small style="font-size: 10px" class="mt-1">{{$t('word104')}}</small>
            </div>
            <img :src="$store.state.slider.image" class="w-full">
            <div @click="share_page = true; background_dark = true" class="text-gray-700 flex flex-col items-center absolute bg-white p-1 rounded shadow-lg" style="top: 3px; right: 3px">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style="transform: rotate(-40deg)" class="w-4"><path stroke-linecap="round" stroke-linejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" /></svg>
                <small style="font-size: 8px" class="mt-1">{{$t('word151')}}</small>
            </div>
        </div>
        <div class="flex justify-between items-center">
            <p class="text-3xl my-4 pr-2">{{ $store.state.slider.title }}</p>
        </div>
        <p class="text-gray-900 text-sm">{{ $store.state.slider.description }}</p>
    </div>

    <div class="rounded-t-lg bg-white fixed w-full z-30 pt-3 hidden"  :class="{'modal_view' : share_page }">
        <div class="flex justify-center py-2"><div style="height: 3px; width:60px; background-color: #999"></div></div>
        <div class="wrapper mx-auto">
            <div class="flex justify-between items-center">
                <p class="text-lg font-medium">{{$t('word151')}}</p>
                <svg @click="share_page = false; background_dark = false" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#666" class="w-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" /></svg>
            </div>
            <div class="mt-4 flex flex-wrap">
                <a style="background-color: rgb(0, 136, 204)" class="text-center grow text-white border border-zinc-200 m-1 p-2 font-medium rounded-lg" :href="'tg://msg?text=https://yola.menu' + $route.fullPath" target="_blank">Telegram</a>
                <a style="background-color: rgb(37, 211, 102)"  class="text-center grow text-white border border-zinc-200 m-1 p-2 font-medium rounded-lg" :href="'https://wa.me/?text=https://yola.menu' + $route.fullPath" target="_blank">Whatsapp</a>
                <a style="background-color: rgb(59, 89, 152)" class="text-center grow text-white border border-zinc-200 m-1 p-2 font-medium rounded-lg" :href="'https://www.facebook.com/sharer.php?u=https://yola.menu' + $route.fullPath" target="_blank">Facebook</a>
                <a style="background-color: rgb(0, 120, 255)" class="text-center grow text-white border border-zinc-200 m-1 p-2 font-medium rounded-lg" :href="'fb-messenger://share/?link=https://yola.menu' + $route.fullPath" target="_blank">Messenger</a>
                <a style="background-color: rgb(102, 92, 172)"  class="text-center grow text-white border border-zinc-200 m-1 p-2 font-medium rounded-lg" :href="'viber://forward?text=https://yola.menu' + $route.fullPath" target="_blank">Viber</a>
                <a style="background-color: rgb(240, 74, 67)" class="text-center grow text-white border border-zinc-200 m-1 p-2 font-medium rounded-lg" :href="'mailto:?body=https://yola.menu' + $route.fullPath" target="_blank">Email</a>
                <a style="background-color: rgb(27, 165, 230)"  class="text-center grow text-white border border-zinc-200 m-1 p-2 font-medium rounded-lg" :href="'sms://+10000?body=https://yola.menu' + $route.fullPath" target="_blank">SMS</a>
            </div>
           <br>
        </div>
    </div>

    <Navigator />
    
</template>

<script>

import  Navigator  from '@/components/Navigator.vue'

export default ({
    components: {Navigator},

    data() {
        return {
            background_dark: false,
            share_page: false,
            not_found: false
        }
    },
    mounted() {
        window.scrollTo(0, 0)
    },
    updated() {
       
    },
    methods: {
    
    }
    
})
</script>   

<style lang="scss">
    
</style>