<template>
    <div :class="[$store.state.background_dark ? 'background_dark' : 'background_dark_none']"  class="h-screen w-full fixed top-0 left-0 right-0 z-20"></div>
    <div :class="[background_dark ? 'background_dark' : 'background_dark_none']"  class="h-screen w-full fixed top-0 left-0 right-0 z-20"></div>
    
    <div v-if="checkout_sent"  class="h-screen w-full fixed top-0 left-0 right-0 z-40 checkout_sent flex justify-center flex-col items-center text-center">
        <div class="wrapper mx-auto flex flex-col items-center justify-center">
            <p class="text-white text-3xl">{{ $t('word87') }} <br> <span class="font-bold">{{ checkout_id }}</span></p>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="my-5" stroke-width="1.5" stroke="white" width="150px"><path stroke-linecap="round" stroke-linejoin="round" d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" /></svg>
            <p class="text-white text-xl">{{ $t('word88') }}</p>
            <button @click="complateBack(); delScroll()" type="button" class="mt-4 border border-zinc-200 w-full p-3 font-medium text-xl rounded-lg bg-white text-blue-600">{{ $t('word28') }}</button>
        </div>
    </div>

    <div class="wrapper mx-auto">
        <div class="flex justify-between items-center mt-3">
            <p class="text-2xl mt-2">{{ $t('word62') }}</p>
            <button @click="$router.push({ name: 'cart' })" type="button" class="px-5 py-2 bg-gray-100 shadow rounded">{{ $t('word104') }}</button>
        </div>

        <div v-if="checkout_error == 1" class=" mt-5 bg-red-600 text-white w-full text-sm rounded font-medium p-3 text-center">
            <span>{{ $t('word63') }}</span>
        </div>
        <div v-else-if="checkout_error == 2" class="mt-5 bg-red-600 text-white w-full text-sm rounded font-medium p-3 text-center">
            <span>{{ $t('word145') }}</span>
        </div>


        <div class="w-full">
            <input disabled type="tel" v-model="checkout_phone"
            class="form-control mt-5 w-full px-4 py-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            :placeholder=" $t('word22') " :class="{'bg-gray-100': $store.state.auth}">
        </div>
        <div class="w-full">
            <input type="text" v-model="checkout_name"
            class="form-control mt-5 w-full px-4 py-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            :placeholder="$t('word21')">
        </div> 

        <div class="mt-6">
            <p class="text-xl mb-2">{{ $t('word66') }}</p>
            <div @click="checkout_address_page = true, background_dark = true" class="flex justify-between items-center">
                <div class="flex items-center">
                    <div class="p-2 bg-gradient-to-r from-blue-600 via-blue-500 to-blue-500 rounded-lg mr-3">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" width="20px"><path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" /><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" /></svg>
                    </div>
                    <div class="flex flex-col justify-center">
                        <p>{{ $t('word67') }}</p>
                        <small v-if="checkout_address.title" class="text-gray-600">
                            {{ checkout_address.title }} <span class="mr-2" v-show="checkout_address.dom">Дом: {{ checkout_address.dom }}</span><span v-show="checkout_address.kv">Кв: {{ checkout_address.kv }}</span>
                        </small>
                        <small v-else class="text-gray-600">{{ $t('word73') }}</small>
                    </div>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" /></svg>
            </div>
        </div>

        <div class="mt-6">
            <p class="text-xl mb-2">{{ $t('word70') }}</p>
            <div @click="checkout_payment_page = true; background_dark = true" class="flex justify-between items-center">
                <div class="flex items-center">
                    <div class="p-2 bg-gradient-to-r from-blue-600 via-blue-500 to-blue-500 rounded-lg mr-3">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" width="20px"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" /></svg>
                    </div>
                    <div class="flex flex-col justify-center">
                        <p>{{ $t('word86') }}</p>
                        <small v-if="checkout_payment == 0" class="text-gray-600">{{ $t('word73') }}</small>
                        <small v-else-if="checkout_payment == 1" class="text-gray-600">{{ $t('word71') }}</small>
                        <small v-else-if="checkout_payment == 2" class="text-gray-600">{{ $t('word72') }}</small>
                    </div>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" /></svg>
            </div>
        </div>
        <div class="mt-4 mb-4">
            <div @click="checkout_checkout_page = true; background_dark = true" class="flex justify-between items-center">
                <div class="flex items-center">
                    <div class="p-2 bg-gradient-to-r from-blue-600 via-blue-500 to-blue-500 rounded-lg mr-3">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" width="20px"><path stroke-linecap="round" stroke-linejoin="round" d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" /></svg>                    
                    </div>
                    <div class="flex flex-col justify-center" style="overflow: hidden">
                        <p>{{ $t('word74') }}</p>
                        <div class="text-xs text-gray-600" style=" max-width: 250px;">{{ checkout_comment }}</div>
                    </div>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" /></svg>
            </div>
        </div>
        <button @click="sendOrder()"  type="button" class="mt-5 border border-zinc-200 w-full p-3 font-medium text-xl rounded-lg bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white">
            {{ $t('word76') }}<br>
            {{ correctPrice($store.state.cart.reduce((t, {quantity, price}) => t + (quantity * price), 0)) }} <span class="text-sm">{{ $store.state.vendor.currency }}</span>
        </button>
       
        <br><br><br><br><br>
    </div>

    <div class="rounded-t-lg bg-white fixed w-full z-30 pt-3 hidden" :class="{'modal_view' : checkout_address_page }">
        <div class="flex justify-center py-2"><div style="height: 3px; width:60px; background-color: #999"></div></div>
        <div class="wrapper">
            <div class="flex justify-between items-center">
                <p class="text-lg font-medium">{{ $t('word77') }}</p>
                <svg @click="checkout_address_page = false, background_dark = false" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#666" class="w-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" /></svg>
            </div>
            <div class=" mt-4" >
                <button @click="selectAddress(c)" v-for="c, i in $store.state.addresses" :key="i" type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-2 rounded-lg" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': checkout_address_id == c.id}">
                    <div class="flex justify-start">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" width="30px"><path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" /><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" /></svg>
                        <div class="ml-1 flex flex-col justify-start items-start">
                            <p class="font-medium">{{ c.title }}</p>
                            <div class="text-sm text-gray-700" >
                               <span :class="{'text-white': checkout_address_id == c.id}" class="mr-2" v-show="c.dom">{{ $t('word68') }}: {{ c.dom }}</span>
                               <span :class="{'text-white': checkout_address_id == c.id}" v-show="c.kv">{{ $t('word69') }}: {{ c.kv }}</span>
                            </div>
                        </div>
                    </div>
                </button>
                <button @click="checkout_address_add_page = true; checkout_address_page = false; checkout_address_status = 0" type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-3 text-lg rounded-lg flex justify-center items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="mr-1" stroke-width="1.5" stroke="black" width="20px"><path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" /></svg>
                    {{ $t('word78') }}
                </button>
                <br> 
            </div>
        </div>
    </div>

    <div class="rounded-t-lg bg-white fixed w-full z-30 pt-3 hidden" :class="{'modal_view' : checkout_address_add_page }">
        <div class="flex justify-center py-2"><div style="height: 3px; width:60px; background-color: #999"></div></div>
        <div class="wrapper">
            <div class="flex justify-between items-center">
                <p class="text-lg font-medium">{{ $t('word78') }}</p>
                <svg @click="checkout_address_add_page = false; checkout_address_page = true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#666" class="w-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" /></svg>
            </div>
            <div v-if="checkout_address_status == 1" class="mt-2 bg-red-600 text-white w-full text-sm rounded font-medium p-3 mb-2 text-center">
                <span>{{ $t('word19') }}</span>
            </div>
            <div v-else-if="checkout_address_status == 2" class="mt-2 bg-red-600 text-white w-full text-sm rounded font-medium p-3 mb-2 text-center">
                <span>{{ $t('word80') }}</span>
            </div>
            
            <small class="pb-2" v-if="checkout_address_loc_status == 1">{{ $t('word81') }}</small>
            <small class="pb-2" v-else-if="checkout_address_loc_status == 2">{{ $t('word82') }}</small>
            <small class="pb-2" v-else-if="checkout_address_loc_status == 3">{{ $t('word83') }}</small>
            <small class="pb-2" v-else-if="checkout_address_loc_status == 4">{{ $t('word84') }}</small>

            <button v-if="checkout_address_loc_status == 1 || checkout_address_loc_status == 3" @click="getLocation()" type="button" class="mt-2 w-full border border-zinc-200 mb-2 p-2 text-lg rounded-lg bg-gradient-to-r from-green-700 via-green-600 to-green-500  text-white flex justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" width="25px"><path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" /><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" /></svg>
                {{ $t('word67') }}
            </button>
            <div>
                <div class="w-full mt-2">
                    <input v-model="checkout_address_title"
                    class="w-full form-controlw-full px-4 py-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    :placeholder="$t('word66')">
                </div>
                <div class="" style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px">
                    <div class="w-full mt-3">
                        <input v-model="checkout_address_dom"
                        class=" form-control w-full px-4 py-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        :placeholder=" $t('word68') ">
                    </div>
                    <div class="w-full mt-3">
                        <input v-model="checkout_address_kv"
                        class=" form-control w-full px-4 py-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        :placeholder="$t('word69')">
                    </div>
                </div>
                <button :disabled="checkout_address_btn" @click="sendAddress()" type="button" class="mt-4 w-full border border-zinc-200 mb-2 p-3 text-lg rounded-lg bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white">{{ $t('word85') }}</button>
                <br> 
            </div>
        </div>
    </div>

    <div class="rounded-t-lg bg-white fixed w-full z-30 pt-3 hidden" :class="{'modal_view' : checkout_payment_page }">
        <div class="flex justify-center py-2"><div style="height: 3px; width:60px; background-color: #999"></div></div>
        <div class="wrapper">
            <div class="flex justify-between items-center">
                <p class="text-lg font-medium">{{ $t('word86') }}</p>
                <svg @click="checkout_payment_page = false, background_dark = false" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#666" class="w-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" /></svg>
            </div>
            <div class=" mt-4" >
                <button @click="selectPayment(1)" type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-3 text-lg rounded-lg" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': checkout_payment == 1}">{{ $t('word71') }}</button>
                <button @click="selectPayment(2)" type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-3 text-lg rounded-lg" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': checkout_payment == 2}">{{ $t('word72') }}</button>
                <br> 
            </div>
        </div>
    </div>

    <div class="rounded-t-lg bg-white fixed w-full z-30 pt-3 hidden" :class="{'modal_view' : checkout_checkout_page }">
        <div class="flex justify-center py-2"><div style="height: 3px; width:60px; background-color: #999"></div></div>
        <div class="wrapper">
            <div class="flex justify-between items-center">
                <p class="text-lg font-medium">{{ $t('word74') }}</p>
                <svg @click="checkout_checkout_page = false, background_dark = false" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#666" class="w-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" /></svg>
            </div>
            <div class=" mt-4" >
                <div class="w-full">
                    <textarea v-model="checkout_comment"
                    class=" form-control  w-full px-4 py-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" 
                    :placeholder="$t('word23')" rows="2"></textarea>
                </div>
                <button @click="checkout_checkout_page = false, background_dark = false" type="button" class="w-full border border-zinc-200 mb-2 p-3 text-lg rounded-lg bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white">{{ $t('word85') }}</button>
                <br> 
            </div>
        </div>
    </div>


    <Navigator />
    
</template>

<script>

import  Navigator  from '@/components/Navigator.vue'

export default ({
    components: {Navigator},

    data() {
        return {
            background_dark: false,
            checkout_name: "",
            checkout_phone: "",

            checkout_error: 0,

            checkout_payment_page: false,
            checkout_payment: 0,

            checkout_checkout_page: false,
            checkout_comment: "",

            checkout_address_page: false,
            checkout_address_id: 0,
            checkout_address: {},
            checkout_address_btn: false,

            checkout_address_add_page: false,
            checkout_address_status: 0,
            checkout_address_title: "",
            checkout_address_dom: "",
            checkout_address_kv: "",
            
            checkout_address_loc_status: 1,
            checkout_address_loc_lat: "",
            checkout_address_loc_lon: "",

            checkout_sent: false,
            checkout_id: 0,
        }
    },
    mounted() {
        window.scrollTo(0, 0)  
        if (this.$store.state.auth) {
            this.checkout_name = this.$store.state.user_name
            this.checkout_phone = this.$store.state.user_phone
       } 
    },
    updated() {
        if (this.$store.state.cart.length == 0) {
                this.$router.push({ name: 'vendor' })
        }
       if (this.$store.state.auth) {
            this.checkout_phone = this.$store.state.user_phone
        } else {
            this.$router.push({ name: 'login' })
        }
    },
    methods: {
        selectPayment(id) {
            this.checkout_payment = id
            this.checkout_payment_page = false
            this.background_dark = false
        },
        selectAddress(addr) {
            this.checkout_address = addr
            this.checkout_address_id = addr.id
            this.checkout_address_page = false
            this.background_dark = false
        },
        getLocation(){
            this.checkout_address_loc_status = 2
            if (!("geolocation" in navigator)) {
                this.checkout_address_loc_status = 3
                return;
            }
            navigator.geolocation.getCurrentPosition(
                (pos) => {
                    setTimeout(()=> {
                        this.checkout_address_loc_lat = pos.coords.latitude
                        this.checkout_address_loc_lon = pos.coords.longitude
                        this.checkout_address_loc_status = 4
                    }, 1500)
                },
                (err) => {
                    this.checkout_address_loc_status = 3
                }
            );
            this.checkout_address_status = 0
        },
        sendAddress(){
            this.checkout_address_btn = true
            this.checkout_address_status = 0 
            if (this.checkout_address_loc_status == 3 || this.checkout_address_loc_status == 4) {
                if (this.checkout_address_title.length) {
                    
                    let nA = {
                        'id': 99999,
                        'title': this.checkout_address_title,
                        'dom': this.checkout_address_dom,
                        'kv': this.checkout_address_kv,
                        'lat': this.checkout_address_loc_lat,
                        'lon': this.checkout_address_loc_lon,
                    }
                    fetch(`${this.$store.state.url}/send/address/`, {
                        method: "POST",
                        headers: {"Content-Type": "application/json",Accept: "application/json"},
                        body: JSON.stringify({
                            token: this.$store.state.auth_token,
                            code: this.$store.state.vendor_code,
                            street: this.checkout_address_title,
                            dom: this.checkout_address_dom,
                            kv: this.checkout_address_kv,
                            lon: this.checkout_address_loc_lon,
                            lat: this.checkout_address_loc_lat,
                            }),
                        })
                        .then((e) => e.json())
                        .then((e) => {
                            if (200 == e.status) {
                                nA['id'] = e.address
                            } 
                        })
                        this.$store.commit("addNewAddress", nA)
                        this.checkout_address_btn = false
                        this.checkout_address_status = 0
                        this.checkout_address_add_page = false
                        this.checkout_address_page = true

                        this.checkout_address_title= ""
                        this.checkout_address_dom= ""
                        this.checkout_address_kv= ""
                        
                        this.checkout_address_loc_status= 1
                        this.checkout_address_loc_lat= ""
                        this.checkout_address_loc_lon= ""
                } else {
                    this.checkout_address_btn = false
                    this.checkout_address_status = 1
                }
            } else {
                this.checkout_address_btn = false
                this.checkout_address_status = 2
            }
        },
        sendOrder() {
            if (this.checkout_name.length && this.checkout_phone.length && this.checkout_payment && Object.keys(this.checkout_address).length) {
                if (navigator.onLine) {
                    console.log(123);
                    let oC = {
                        'total': this.$store.state.cart.reduce((t, {quantity, price}) => t + (quantity * price), 0),
                        'address': this.checkout_address_title,
                        'date_created': "",
                        }
                    fetch(`${this.$store.state.url}/order/`, {
                        method: "POST",
                        headers: {"Content-Type": "application/json",Accept: "application/json"},
                        body: JSON.stringify({
                            auth: this.$store.state.auth,
                            token: this.$store.state.auth_token,
                            code: this.$store.state.vendor_code,
                            phone: this.checkout_phone,
                            fio: this.checkout_name,
                            lat: this.checkout_address.lat,
                            lon: this.checkout_address.lon,
                            address: this.checkout_address.title,
                            dom: this.checkout_address.dom,
                            kv: this.checkout_address.kv,
                            comment: this.checkout_comment,
                            payment: this.checkout_payment,
                            cart: this.$store.state.cart,
                            cart_total: this.$store.state.cart.reduce((t, {quantity, price}) => t + (quantity * price), 0)
                            }),
                        })
                        .then((e) => e.json())
                        .then((e) => {
                            if (200 == e.status) {
                                oC['date_created'] = e.date_created
                                this.checkout_id = e.order
                            }
                        })
                    this.checkout_sent = true
                    this.$store.commit("addNewOrder", oC)
                } else {
                    this.checkout_error = 2
                }
            } else {
                this.checkout_error = 1
            }
        },
        complateBack() {
            this.checkout_payment_page = false
            this.checkout_payment = 0

            this.checkout_checkout_page = false
            this.checkout_comment = ""

            this.checkout_address_page = false
            this.checkout_address_id = 0
            this.checkout_address = {}
            this.checkout_address_btn = false

            this.checkout_address_add_page =false
            this.checkout_address_status = 0
            this.checkout_address_title = ""
            this.checkout_address_dom = ""
            this.checkout_address_kv = ""
            
            this.checkout_address_loc_status = 1
            this.checkout_address_loc_lat = ""
            this.checkout_address_loc_lon = ""

            this.checkout_sent = false
            this.checkout_id = 0
            this.$store.state.cart = []
            this.$router.push({ name: 'vendor' })
        },
        delScroll() {
            localStorage.removeItem('scroll')
        },
        correctPrice(x) {
                let t = String(x)
                let f = ""
                if (t.length == 4) {
                    return f = t[0] + ' ' + t.slice(1)
                } else if (t.length == 5) {
                    return f = t.slice(0,2) + ' ' + t.slice(2)
                } else if (t.length == 6) {
                    return f = t.slice(0,3) + ' ' + t.slice(3)
                } else if (t.length == 7) {
                    return f = t[0] + ' ' + t.slice(1, 4) + " " + t.slice(4)
                } else if (t.length == 8) {
                    return f = t.slice(0,2) + ' ' + t.slice(2, 5) + " " + t.slice(5)
                } else if (t.length == 9) {
                    return f = t.slice(0,3) + ' ' + t.slice(3, 6) + " " + t.slice(6)
                } else {
                    return f = t
                }
        },
    }
    
})
</script>   

<style lang="scss">
    .checkout_sent {
        animation-name: checkout_sent;
        animation-duration: .5s;
        --webkit-animations-duration: .5s;
        background-color: rgb(37, 99 ,235, 1)
    }
    @keyframes checkout_sent {
        0% {
            background-color: rgb(37, 99 ,235, 0)
        }
        100% {
            background-color: rgb(37, 99 ,235, 1)
        }
    }


</style>