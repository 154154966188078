<template>
    <div :class="[$store.state.background_dark ? 'background_dark' : 'background_dark_none']"  class="h-screen w-full fixed top-0 left-0 right-0 z-20"></div>

    
    <div class="wrapper flex flex-col justify-center items-center mx-auto">
            <img class="w-6/12 mt-3" :src="$store.state.vendor.logo" alt="">
            <p class="mb-5 mt-4 text-3xl">{{$t('word90')}}</p>
            
            <div v-if="login_error == 1" class="bg-red-600 text-white w-full text-sm rounded font-medium p-3 text-center mb-5">
                <span>{{$t('word63')}}</span>
            </div>
            <div v-else-if="login_error == 2" class="bg-red-600 text-white w-full text-sm rounded font-medium p-3 text-center mb-5">
                <span>{{$t('word53')}}</span>
            </div>
            <div v-else-if="login_error == 3" class="bg-red-600 text-white w-full text-sm rounded font-medium p-3 text-center mb-5">
                <span>{{$t('word145')}}</span>
            </div>
            <div v-else-if="login_error == 4" class="bg-red-600 text-white w-full text-sm rounded font-medium p-3 text-center mb-5">
                <span>{{$t('word91')}}</span>
            </div>

            <div v-else-if="$store.state.login_message == 777" class="bg-green-600 text-white w-full text-sm rounded font-medium p-3 text-center mb-5">
                <span>{{$t('word93')}}</span>
            </div>
            <div v-else-if="$store.state.login_message == 888" class="bg-green-600 text-white w-full text-sm rounded font-medium p-3 text-center mb-5">
                <span>{{$t('word92')}}</span>
            </div>

            <div class="w-full relative">
                <input type="tel" v-model="login_phone"
                class="form-control w-full py-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                :placeholder="$t('word22')" style="padding-left: 70px"/>
                <span class="absolute text-xl font-normal text-gray-500" style="top: 24.6%; left: 12px">+{{ $store.state.vendor.phone_code }}</span>
            </div>

            <div class="w-full relative">
                <input :type="password_type" v-model="login_password"
                class=" form-control mt-5 w-full px-4 py-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                :placeholder="$t('word136')"/>
                <svg @click="password_show = false, password_type = 'password'" v-if="password_show" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-7 absolute" style="top: 45%; right: 15px"><path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" /></svg>
                <svg @click="password_show = true, password_type = 'text'" v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-7 absolute" style="top: 45%; right: 15px"><path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" /><path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" /></svg>
            </div>

            <div class="flex justify-between w-full my-4 ">
                <span @click="$router.push({ name: 'reset' })" class="text-gray-500">{{$t('word94')}}</span>
                <span @click="$router.push({ name: 'reset' })" class="text-gray-900 font-medium">{{$t('word95')}}</span>
            </div>

            <button @click="auth" :disabled="login_btn" type="button" class="mt-2 border border-zinc-200 w-full p-3 font-medium text-xl rounded-lg bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white flex justify-center items-center">
                {{$t('word96')}}
                <svg v-show="login_btn" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="animate-spin w-5 h-5 ml-3"><path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" /></svg>
            </button>
            <button @click="$router.push({ name: 'register' })" type="button" class="mt-4 border border-zinc-200 w-full p-2 font-medium text-lg rounded-lg bg-gradient-to-r from-gray-400 via-gray-500 to-gray-500  text-white">{{$t('word97')}}</button>
            <br><br><br>
        </div>

   <Navigator />
 
</template>

<script>

import  Navigator  from '@/components/Navigator.vue'

export default ({
    components: {Navigator},

    data() {
        return {
            background_dark: false,

            password_show: false,
            password_type: 'password',
            login_error: 0,
            login_btn: false,
            login_phone: "",
            login_password: ""
        }
    },
    mounted() {
       
    }, 
    updated() {
        if (this.$store.state.auth) {
                this.$router.push({ name: 'profile' })
            }
    },
    methods: {
        auth() {
            this.login_btn = true;
            this.login_error = 0
            if (this.login_phone.length && this.login_password.length ) {
                if (this.login_phone.length == this.$store.state.vendor.phone_length) {
                    if (navigator.onLine) { 
                        fetch(`${this.$store.state.url}/login/auth/`, {
                            method: "POST",
                            headers: {"Content-Type": "application/json",Accept: "application/json",},
                            body: JSON.stringify({
                                phone: this.login_phone,
                                password: this.login_password,
                                code: this.$store.state.vendor_code,
                                })
                            })
                            .then((e) => e.json())
                            .then((e) => {
                                if (200 == e.status) {
                                    this.$store.commit("setAuth", e);
                                    this.login_error = 0
                                    this.login_phone = ""
                                    this.login_password = ""
                                    this.$router.push({ name: 'profile' })
                                } else {
                                    this.login_error = 4
                                    this.login_password = ""
                                }
                                this.login_btn = false;
                            })
                                
                    } else {
                        this.login_error = 3
                        this.login_btn = false;
                    }
                } else {
                    this.login_error = 2
                    this.login_btn = false;
                }
            } else {
                this.login_error = 1
                this.login_btn = false;
            }
        }
    }
    
})
</script>   

<style lang="scss">
    
</style>