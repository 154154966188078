<template>
     <div :class="[$store.state.background_dark ? 'background_dark' : 'background_dark_none']"  class="h-screen w-full fixed top-0 left-0 right-0 z-20"></div>    
</template>

<script>

export default ({

    data() {
        return {
           
        }
    },
    mounted() {
        this.$router.push({ name: 'vendor' })
    },
    updated() {
        this.$router.push({ name: 'vendor' })
    },
    methods: {
      
    }
    
})
</script>   

<style lang="scss">
    
</style>