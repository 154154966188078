<template>
    <div :class="[$store.state.background_dark ? 'background_dark' : 'background_dark_none']"  class="h-screen w-full fixed top-0 left-0 right-0 z-20"></div>
    <div :class="[background_dark ? 'background_dark' : 'background_dark_none']"  class="h-screen w-full fixed top-0 left-0 right-0 z-20"></div>

    <div class="wrapper mx-auto">
            <div class="flex justify-between items-center my-3">
                <p class="text-2xl">{{$t('word126')}}</p>
                <button @click="logout()" type="button" class="text-gray-600 bg-white border border-zinc-200 m-1 px-4 py-1 font-medium rounded-lg">{{$t('word127')}}</button>
            </div>

            <div class="p-4 border rounded shadow-md">
                <div v-if="profile_error == 777" class="bg-green-600 text-white w-full text-sm rounded font-medium p-3 mb-2 text-center">
                    <span>{{$t('word153')}}</span>
                </div>

                <div class="flex justify-between items-end pb-3" style="border-bottom:1px solid #cfcfcf">
                    <div>
                        <small class="text-gray-500">{{$t('word22')}}</small>
                        <p>+{{ $store.state.user_phone }}</p>
                    </div>
                </div>

                <div class="flex justify-between items-end py-3" style="border-bottom:1px solid #cfcfcf">
                    <div>
                        <small class="text-gray-500">{{$t('word21')}}</small>
                        <p>{{ $store.state.user_name }}</p>
                    </div>
                    <div @click="showName()" class="flex items-center text-gray-600">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 mr-1"><path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" /></svg>
                        <span>{{$t('word128')}}</span>
                    </div>
                </div>

                <div class="flex justify-between items-end py-3" style="border-bottom:1px solid #cfcfcf">
                    <div>
                        <small class="text-gray-500">{{$t('word111')}}</small>
                        <p>{{ $store.state.account_current_day }} - {{ $store.state.account_current_month }} -  {{ $store.state.account_current_year }}</p>
                    </div>
                    <div @click="showBirthday()" class="flex items-center text-gray-600">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 mr-1"><path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" /></svg>
                        <span>{{$t('word128')}}</span>
                    </div>
                </div>
                <div class="flex justify-between items-center pt-3">
                    <div>
                        <small class="text-gray-500">{{$t('word136')}}</small>
                        <p class="text-xl">************</p>
                    </div>
                    <div @click="showPassword()" class="flex items-center text-gray-600">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 mr-1"><path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" /></svg>
                        <span>{{$t('word128')}}</span>
                    </div>
                </div>
            </div>

           
            

            <div class="flex justify-between items-center mt-6 mb-3">
                <p class="text-xl">{{$t('word130')}}</p>
            </div>

            <div class="p-4 border rounded shadow-md">
                <div v-for="c, i in $store.state.orders" :key="i" class="flex justify-between items-center py-3" style="border-bottom:1px solid #cfcfcf">
                    <div>
                        <p>{{ c.date_created }}</p>
                        <small class="text-gray-500">{{ c.address }}</small>
                    </div>
                    <p class="text-xl">{{ correctPrice(c.total) }} <span class="text-sm text-gray-600">{{ $store.state.vendor.currency }}</span></p>
                </div>
            </div>

        </div>
        <br><br><br>
   <Navigator />

    <div class="rounded-t-lg bg-white fixed w-full z-30 pt-3 hidden" :class="{'modal_view' : profile_name_page }">
        <div class="flex justify-center py-2"><div style="height: 3px; width:60px; background-color: #999"></div></div>
        <div class="wrapper mx-auto">
            <div class="flex justify-between items-center">
                <p class="text-lg font-medium">{{$t('word131')}}</p>
                <svg @click="profile_name_page = false; background_dark = false" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#666" class="w-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" /></svg>
            </div>
            <div v-if="profile_error == 1" class="bg-red-600 text-white w-full text-sm rounded font-medium p-3 text-center my-5">
                <span>{{$t('word19')}}</span>
            </div>
            <div class="my-5">
                <input  type="text" v-model="$store.state.user_temp_name" class=" form-control w-full px-4 py-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition" :placeholder="$t('word21')"/>
            </div>
            <button @click="setName()" :disabled="profile_btn" type="button" class="mt-5 mb-3 border border-zinc-200 w-full p-3 font-medium text-xl rounded-lg bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white flex items-center justify-center">
                {{$t('word128')}}
                <svg v-show="profile_btn" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="animate-spin w-5 h-5 ml-3"><path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" /></svg>
            </button>
        </div>
    </div>

    <div class="rounded-t-lg bg-white fixed w-full z-30 pt-3 hidden" :class="{'modal_view' : profile_password_page }">
        <div class="flex justify-center py-2"><div style="height: 3px; width:60px; background-color: #999"></div></div>
        <div class="wrapper mx-auto">
            <div class="flex justify-between items-center">
                <p class="text-lg font-medium">{{$t('word137')}}</p>
                <svg @click="profile_password_page = false; background_dark = false" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#666" class="w-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" /></svg>
            </div>
            <div v-if="profile_error == 1" class="bg-red-600 text-white w-full text-sm rounded font-medium p-3 text-center mt-5">
                <span>{{$t('word100')}}</span>
            </div>
            <div class="w-full relative">
                <input :type="password_type" v-model="password"
                class=" form-control mt-5 w-full px-4 py-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                :placeholder="$t('word102')"/>
                <svg @click="password_show = false, password_type = 'password'" v-if="password_show" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-7 absolute" style="top: 45%; right: 15px"><path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" /></svg>
                <svg @click="password_show = true, password_type = 'text'" v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-7 absolute" style="top: 45%; right: 15px"><path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" /><path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" /></svg>
            </div>
            <button @click="setPassword()" :disabled="profile_btn" type="button" class="mt-5 mb-3 border border-zinc-200 w-full p-3 font-medium text-xl rounded-lg bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white flex items-center justify-center">
                {{$t('word128')}}
                <svg v-show="profile_btn" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="animate-spin w-5 h-5 ml-3"><path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" /></svg>
            </button>
        </div>
    </div>

    <div class="rounded-t-lg bg-white fixed w-full z-30 pt-3 hidden" :class="{'modal_view' : profile_birthday_page }">
        <div class="flex justify-center py-2"><div style="height: 3px; width:60px; background-color: #999"></div></div>
        <div class="wrapper mx-auto">
            <div class="flex justify-between items-center">
                <p class="text-lg font-medium">{{$t('word135')}}</p>
                <svg @click="closeModal()" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#666" class="w-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" /></svg>
            </div>
            <div v-if="profile_error == 1" class="bg-red-600 text-white w-full text-sm rounded font-medium p-3 text-center my-5">
                <span>{{$t('word19')}}</span>
            </div>
            <div class="my-5" style="display: grid; grid-template-columns: repeat(3, 1fr); grid-gap: 7px">
                <input @click="showDay()"   readonly type="text" v-model="$store.state.account_temp_day" class=" form-control w-full px-4 py-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition" :placeholder="$t('word106')"/>
                <input @click="showMonth()" readonly type="text" v-model="$store.state.account_temp_month" class=" form-control w-full px-4 py-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition" :placeholder="$t('word107')"/>
                <input @click="showYear()"  readonly type="text" v-model="$store.state.account_temp_year" class=" form-control w-full px-4 py-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition" :placeholder="$t('word108')"/>
            </div>
            <button @click="setBirthday()" :disabled="profile_btn" type="button" class="mt-5 mb-3 border border-zinc-200 w-full p-3 font-medium text-xl rounded-lg bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white flex items-center justify-center">
                {{$t('word128')}}
                <svg v-show="profile_btn" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="animate-spin w-5 h-5 ml-3"><path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" /></svg>
            </button>
        </div>
    </div>

    <div class="rounded-t-lg bg-white fixed w-full z-30 pt-3 hidden" :class="{'modal_view' : profile_birthday_detail_page == 1 }">
        <div class="flex justify-center py-2"><div style="height: 3px; width:60px; background-color: #999"></div></div>
        <div class="wrapper mx-auto">
            <div class="flex justify-between items-center">
                <p class="text-lg font-medium"> {{$t('word111')}}</p>
                <svg @click="profile_birthday_detail_page = 0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#666" class="w-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" /></svg>
            </div>
            <div class=" mt-4" style="height: 50vh; overflow: auto">
                <button @click="selectDay(c)" v-for="c, i in $store.state.reg_month_days" v-bind:key="i"  type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-2 font-medium rounded-lg text-xl" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': c == $store.state.account_temp_day}">{{ c }}</button>
                <br>
            </div>
        </div>
    </div>

    <div class="rounded-t-lg bg-white fixed w-full z-30 pt-3 hidden" :class="{'modal_view' : profile_birthday_detail_page == 2 }">
        <div class="flex justify-center py-2"><div style="height: 3px; width:60px; background-color: #999"></div></div>
        <div class="wrapper mx-auto">
            <div class="flex justify-between items-center">
                <p class="text-lg font-medium"> {{$t('word112')}}</p>
                <svg @click="profile_birthday_detail_page = 0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#666" class="w-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" /></svg>
            </div>
            <div class=" mt-4" style="height: 50vh; overflow: auto">
                <button @click="selectMonth(1)" type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-2 font-medium rounded-lg text-xl" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': $store.state.account_temp_month_code == 1}"> {{$t('word114')}} </button>
                <button @click="selectMonth(2)" type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-2 font-medium rounded-lg text-xl" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': $store.state.account_temp_month_code == 2}"> {{$t('word115')}} </button>
                <button @click="selectMonth(3)" type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-2 font-medium rounded-lg text-xl" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': $store.state.account_temp_month_code == 3}"> {{$t('word116')}} </button>
                <button @click="selectMonth(4)" type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-2 font-medium rounded-lg text-xl" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': $store.state.account_temp_month_code == 4}"> {{$t('word117')}} </button>
                <button @click="selectMonth(5)" type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-2 font-medium rounded-lg text-xl" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': $store.state.account_temp_month_code == 5}"> {{$t('word118')}} </button>
                <button @click="selectMonth(6)" type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-2 font-medium rounded-lg text-xl" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': $store.state.account_temp_month_code == 6}"> {{$t('word119')}} </button>
                <button @click="selectMonth(7)" type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-2 font-medium rounded-lg text-xl" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': $store.state.account_temp_month_code == 7}"> {{$t('word120')}} </button>
                <button @click="selectMonth(8)" type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-2 font-medium rounded-lg text-xl" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': $store.state.account_temp_month_code == 8}"> {{$t('word121')}} </button>
                <button @click="selectMonth(9)" type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-2 font-medium rounded-lg text-xl" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': $store.state.account_temp_month_code == 9}"> {{$t('word122')}} </button>
                <button @click="selectMonth(10)" type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-2 font-medium rounded-lg text-xl" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': $store.state.account_temp_month_code == 10}"> {{$t('word123')}} </button>
                <button @click="selectMonth(11)" type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-2 font-medium rounded-lg text-xl" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': $store.state.account_temp_month_code == 11}"> {{$t('word124')}} </button>
                <button @click="selectMonth(12)" type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-2 font-medium rounded-lg text-xl" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': $store.state.account_temp_month_code == 12}"> {{$t('word125')}} </button>
                <br>
            </div>
        </div>
    </div>

    <div class="rounded-t-lg bg-white fixed w-full z-30 pt-3 hidden" :class="{'modal_view' : profile_birthday_detail_page == 3 }">
        <div class="flex justify-center py-2"><div style="height: 3px; width:60px; background-color: #999"></div></div>
        <div class="wrapper mx-auto">
            <div class="flex justify-between items-center">
                <p class="text-lg font-medium"> {{$t('word113')}}</p>
                <svg @click="profile_birthday_detail_page = 0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#666" class="w-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" /></svg>
            </div>
            <div class=" mt-4" style="height: 50vh; overflow: auto">
                <button @click="selectYear(c)" v-for="c, i in $store.state.years" v-bind:key="i"  type="button" class="w-full text-gray-900 bg-white border border-zinc-200 mb-2 p-2 font-medium rounded-lg text-xl" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400  text-white': c == this.$store.state.account_temp_year}">{{ c }}</button>
                <br>
            </div>
        </div>
    </div>

</template>

<script>

import  Navigator  from '@/components/Navigator.vue'

let x;
export default ({
    components: {Navigator},

    data() {
        return {
            background_dark: false,

            profile_birthday_page: false,
            profile_birthday_detail_page: 0,
            profile_btn: false,

            profile_error: 0,

            profile_name_page: false,
            profile_password_page: false,
            
            

            profile_password: "",

            password: "",
            password_show: false,
            password_type: 'password',
        }
    },
    mounted() {
        window.scrollTo(0, 0)
        },
    updated() {
        if (!this.$store.state.auth) {
                this.$router.push({ name: 'login' })
            }
    },
    methods: {
       
        showBirthday() {
            this.background_dark = true
            this.profile_birthday_page = true
        },
        showDay() {
            this.background_dark = true
            this.profile_birthday_detail_page = 1
        },
        selectDay(day) {
            this.$store.state.account_temp_day = day
            this.background_dark = false
            this.profile_birthday_detail_page = 0
        },
        showMonth() {
            this.background_dark = true
            this.profile_birthday_detail_page = 2
        },
        selectMonth(month) {
            this.$store.state.account_temp_month_code = month
            this.background_dark = false
            this.profile_birthday_detail_page = 0
            switch (month) {
                case 1:
                    this.$store.state.account_temp_month =  this.$t('word114');
                    this.$store.state.reg_month_days = this.$store.state.days_31
                    break
                case 2:
                    this.$store.state.account_temp_month = this.$t('word115');
                    this.$store.state.reg_month_days = this.$store.state.days_28;
                    this.$store.state.account_temp_day > 28 ? this.$store.state.account_temp_day = 28 : "";
                    break
                case 3:
                    this.$store.state.account_temp_month = this.$t('word116');
                    this.$store.state.reg_month_days = this.$store.state.days_31
                    break
                case 4:
                    this.$store.state.account_temp_month = this.$t('word117');
                    this.$store.state.reg_month_days = this.$store.state.days_30
                    this.$store.state.account_temp_day > 30 ? this.$store.state.account_temp_day = 30 : "";
                    break
                case 5:
                    this.$store.state.account_temp_month = this.$t('word118');
                    this.$store.state.reg_month_days = this.$store.state.days_31
                    break
                case 6:
                    this.$store.state.account_temp_month = this.$t('word119');
                    this.$store.state.reg_month_days = this.$store.state.days_30
                    this.$store.state.account_temp_day ? this.$store.state.account_temp_day : "";
                    break
                case 7:
                    this.$store.state.account_temp_month = this.$t('word120');
                    this.$store.state.reg_month_days = this.$store.state.days_31
                    break
                case 8:
                    this.$store.state.account_temp_month = this.$t('word121');
                    this.$store.state.reg_month_days = this.$store.state.days_31
                    break
                case 9:
                    this.$store.state.account_temp_month = this.$t('word122');
                    this.$store.state.reg_month_days = this.$store.state.days_30
                    this.$store.state.account_temp_day ? this.$store.state.account_temp_day : "";
                    break
                case 10:
                    this.$store.state.account_temp_month = this.$t('word123');
                    this.$store.state.reg_month_days = this.$store.state.days_31
                    break
                case 11:
                    this.$store.state.account_temp_month = this.$t('word124');
                    this.$store.state.reg_month_days = this.$store.state.days_30
                    this.$store.state.account_temp_day ? this.$store.state.account_temp_day : "";
                    break
                case 12:
                    this.$store.state.account_temp_month = this.$t('word125');
                    this.$store.state.reg_month_days = this.$store.state.days_31
                    break
            }
        },
        showYear() {
            this.background_dark = true
            this.profile_birthday_detail_page = 3
        },
        selectYear(year) {
            this.$store.state.account_temp_year = year
            this.background_dark = false
            this.profile_birthday_detail_page = 0
        },
        closeModal() {
            this.background_dark = false
            this.profile_birthday_page = false
            this.profile_birthday_detail_page = 0
        },
        setBirthday() {
            this.profile_btn = true
            this.profile_error = 0
            if (this.$store.state.account_temp_year && this.$store.state.account_temp_month && this.$store.state.account_temp_day ) {
                if (this.$store.state.account_current_year != this.$store.state.account_temp_year || this.$store.state.account_current_month_code != this.$store.state.account_temp_month_code || this.$store.state.account_current_day != this.$store.state.account_temp_day) {
                    fetch(`${this.$store.state.url}/login/edit/birthday/`, {
                        method: "POST",
                        headers: {"Content-Type": "application/json",Accept: "application/json"},
                        body: JSON.stringify({
                            token: this.$store.state.auth_token,
                            birthday: `${this.$store.state.account_temp_year}-${this.$store.state.account_temp_month_code}-${this.$store.state.account_temp_day}`
                            }),
                        })
                        this.profile_btn = false
                        this.background_dark = false
                        this.profile_birthday_page = false
                        this.profile_birthday_detail_page = 0
                        this.profile_error = 777
                
                        this.$store.state.account_current_year = this.$store.state.account_temp_year
                        this.$store.state.account_current_month_code = this.$store.state.account_temp_month_code
                        this.$store.state.account_current_month = this.$store.state.account_temp_month
                        this.$store.state.account_current_day = this.$store.state.account_temp_day
                } else {
                    this.profile_error = 0
                    this.profile_btn = false
                    this.background_dark = false
                    this.profile_birthday_page = false
                    this.profile_birthday_detail_page = 0
                }
            } else {
                this.profile_error = 1
                this.profile_btn = false
            }
        }, 

        showName() {
            this.background_dark = true
            this.profile_name_page = true
        },
        setName() {
            this.profile_btn = true
            this.profile_error = 0
            if (this.$store.state.user_temp_name.length) {
                if (this.$store.state.user_temp_name != this.$store.state.user_name) {
                    fetch(`${this.$store.state.url}/login/edit/fio/`, {
                        method: "POST",
                        headers: {"Content-Type": "application/json",Accept: "application/json",},
                        body: JSON.stringify({
                            token: this.$store.state.auth_token,
                            fio: this.$store.state.user_temp_name
                            })
                        })
                        this.$store.state.user_name = this.$store.state.user_temp_name
                        this.profile_error = 777
                        this.background_dark = false
                        this.profile_name_page = false
                        this.profile_btn = false
                } else {
                    this.profile_error = 0
                    this.profile_btn = false
                    this.background_dark = false
                    this.profile_name_page = false
                }
            } else {
                this.profile_error = 1
                this.profile_btn = false
            }
        },
        showPassword() {
            this.background_dark = true
            this.profile_password_page = true
        },
        setPassword() {
            this.profile_error = 0
            this.profile_btn = false
            if (this.password.length > 5) {
                fetch(`${this.$store.state.url}/login/edit/password/`, {
                    method: "POST",
                    headers: {"Content-Type": "application/json",Accept: "application/json",},
                    body: JSON.stringify({
                        token: this.$store.state.auth_token,
                        password: this.password
                        })
                    })
                    this.profile_btn = false
                    localStorage.removeItem('authToken')
                    this.$store.state.auth = false
                    this.$router.push({ name: 'login' })
            } else {
                this.profile_error = 1
                this.profile_btn = false
            }
        },
        logout() {
            localStorage.removeItem('authToken')
            this.$store.state.auth = false
            this.$router.push({ name: 'login' })
        },

        correctPrice(x) {
                let t = String(x)
                let f = ""
                if (t.length == 4) {
                    return f = t[0] + ' ' + t.slice(1)
                } else if (t.length == 5) {
                    return f = t.slice(0,2) + ' ' + t.slice(2)
                } else if (t.length == 6) {
                    return f = t.slice(0,3) + ' ' + t.slice(3)
                } else if (t.length == 7) {
                    return f = t[0] + ' ' + t.slice(1, 4) + " " + t.slice(4)
                } else if (t.length == 8) {
                    return f = t.slice(0,2) + ' ' + t.slice(2, 5) + " " + t.slice(5)
                } else if (t.length == 9) {
                    return f = t.slice(0,3) + ' ' + t.slice(3, 6) + " " + t.slice(6)
                } else {
                    return f = t
                }
        },
    }
    
})
</script>   

<style lang="scss">
    
</style>